<template>
  <div>
    <home-container>
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 80px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Wie entsteht eine Zusammenarbeit?
      </div>

      <v-container>
        <v-row>
          <v-col cols="12" sm="">
            <div class="text-h6" v-if="smAndDown">
              1. Kennenlernen
            </div>
            <v-icon icon="$welcome" size="150" :class="{ 'mb-5': mdAndUp }" />
            <div class="text-h5 mb-4" v-if="mdAndUp">
              1. Kennenlernen
            </div>
            <p style="max-width: 250px; margin: auto">
              Wir reden in einem kostenfreien Gespräch über Ihre Ziele und zeigen ihnen, wie wir diese mit Social Media erreichen werden.
            </p>
          </v-col>
          
          <span style="font-size: 100px; color: lightgrey" v-if="mdAndUp">&#8594;</span>
          
          <v-col cols="12" sm="">
            <div class="text-h6" :class="{ 'mt-5': xs }" v-if="smAndDown">
              2. Passt es?
            </div>
            <v-icon icon="$feedback" size="150" :class="{ 'mb-5': mdAndUp, 'mt-2': smAndDown }" />
            <div class="text-h5 mb-4" v-if="mdAndUp">
              2. Passt es?
            </div>
            <p style="max-width: 200px; margin: auto">
              Nur wenn die Chemie stimmt und wir glauben, dass wir Ihnen wirklich helfen können, sagen wir ja.
            </p>
          </v-col>

          <span style="font-size: 100px; color: lightgrey" v-if="mdAndUp">&#8594;</span>

          <v-col cols="12" sm="">
            <div class="text-h6" :class="{ 'mt-5': xs }" v-if="smAndDown">
              3. Partnerschaft
            </div>
            <v-icon icon="$partnership" :size="mdAndUp ? 150 : 140" :class="{ 'mb-5': mdAndUp, 'mt-2': smAndDown, 'mb-3': smAndDown }" />
            <div class="text-h5 mb-4" v-if="mdAndUp">
              3. Partnerschaft
            </div>
            <p style="max-width: 250px; margin: auto">
              Wir entwickeln die perfekt auf Sie abgestimmte Social Media Strategie und begleiten Sie langfristig bei der Umsetzung.
            </p>
          </v-col>
        </v-row>

        <contact-button placement="1" :calendly="true" class="bg-primary text-white" style="margin-top: 80px" v-if="mdAndUp">
          Termin zum Kennenlernen vereinbaren
        </contact-button>
      </v-container>
      <contact-button placement="1" :calendly="true" class="bg-primary text-white mt-5 mb-6" v-if="smAndDown">
        Termin zum Kennenlernen vereinbaren
      </contact-button>

    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { mdAndUp, smAndDown } = useDisplay()

    return { mdAndUp, smAndDown }
  },
  components: {
    HomeContainer
  }
}
</script>
