<template>
  <div>
    <home-container>
      <v-container>
        <v-row>
          <v-col>
            <!-- Hero Image -->
            <div style="margin: auto" :style="{ 'max-width': xs ? '100%' : '60%' }">
              <v-img src="@\assets\journal-page\Social_Media_Journal_Unvergessen_Agentur_Front.jpg" alt="Kunden und Personal finden durch Social Media"></v-img>
            </div>

            <!-- Description -->
            <div class="text-left" style="margin: 40px auto"  :style="{ 'max-width': xs ? '100%' : '80%' }">
              <h3>
                <b>So werden Sie mit ihrem Bestattungsinstitut in ihrer Region zum Marktführer.</b><br><br>
                In unserem kostenlosen Bestatter-Journal lernen Sie:
              </h3>
              <div style="padding: 4px 30px">
                <ul>
                  <li>
                    Wie Sie neue Kunden durch Social Media finden
                  </li>
                  <li>
                    Wie Sie ganz leicht das richtige Personal für Ihr Unternehmen finden
                  </li>
                  <li>
                    Wie Sie den Anschluss an die jüngere Generation nicht verlieren
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col>
            <div style="display: table; width: 100%; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                <h2>
                  Unser kostenloses Social Media Journal<br>
                  Jetzt herunterladen
                </h2>
                <div :style="{ padding: xs ? '20px 0 10px' : '40px 20px 20px' }">
                  <v-progress-linear
                    model-value="50"
                    color="light-green-darken-4"
                    height="20"
                    striped
                    style="border-radius: 15px"
                  ></v-progress-linear>
                </div>
                <download-form></download-form>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'

import DownloadForm from '@/components/journal-page/forms/DownloadFormComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, smAndUp} = useDisplay()

    return { xs, smAndUp}
  },
  components: {
    HomeContainer,
    DownloadForm
  }
}
</script>
