<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="148.623" height="165.514" viewBox="0 0 148.623 165.514">
    <defs>
      <filter id="Icon_awesome-linkedin" x="27.663" y="113.968" width="51.545" height="51.545" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Gruppe_906" data-name="Gruppe 906" transform="translate(-886.608 -4155.296)">
      <path id="Icon_material-phone-iphone" data-name="Icon material-phone-iphone" d="M67.594,1.5H21.808A14.313,14.313,0,0,0,7.5,15.808V113.1a14.313,14.313,0,0,0,14.308,14.308H67.594A14.313,14.313,0,0,0,81.9,113.1V15.808A14.313,14.313,0,0,0,67.594,1.5Zm2.862,107.037H18.946V18.67H70.456Z" transform="translate(916.662 4153.796)" fill="#3f3f3f"/>
      <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M40.773,20.386A20.386,20.386,0,1,0,17.2,40.526V26.28H12.022V20.386H17.2V15.895c0-5.109,3.042-7.931,7.7-7.931a31.374,31.374,0,0,1,4.564.4v5.014H26.894c-2.532,0-3.322,1.572-3.322,3.184v3.827h5.654l-.9,5.893h-4.75V40.526A20.394,20.394,0,0,0,40.773,20.386Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 888.301, 4184.279)" fill="#3f3f3f"/>
      <g id="Icon_feather-instagram" data-name="Icon feather-instagram" transform="matrix(0.966, 0.259, -0.259, 0.966, 999.515, 4228.539)">
        <path id="Pfad_1092" data-name="Pfad 1092" d="M8.768,0H26.305a8.545,8.545,0,0,1,8.768,8.3v16.6a8.545,8.545,0,0,1-8.768,8.3H8.768A8.545,8.545,0,0,1,0,24.906V8.3A8.545,8.545,0,0,1,8.768,0Z" transform="translate(0 0)" fill="none" stroke="#3f3f3f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Pfad_1093" data-name="Pfad 1093" d="M13.954,5.986A7.015,7.015,0,1,1,8.044.076a7.015,7.015,0,0,1,5.91,5.91Z" transform="translate(10.598 9.52)" fill="none" stroke="#3f3f3f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Pfad_1094" data-name="Pfad 1094" d="M0,0H0" transform="translate(27.182 7.472)" fill="none" stroke="#3f3f3f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
      <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok" d="M19.2.035C21.5,0,23.781.018,26.061,0A10.923,10.923,0,0,0,29.13,7.313a12.367,12.367,0,0,0,7.436,3.139v7.067a18.771,18.771,0,0,1-7.365-1.7,21.667,21.667,0,0,1-2.841-1.631c-.018,5.121.018,10.241-.035,15.345a13.4,13.4,0,0,1-2.367,6.909,13.065,13.065,0,0,1-10.364,5.629,12.787,12.787,0,0,1-7.155-1.806,13.224,13.224,0,0,1-6.4-10.013C0,29.374-.016,28.5.019,27.638a13.2,13.2,0,0,1,15.31-11.715c.035,2.6-.07,5.191-.07,7.786a6.017,6.017,0,0,0-7.681,3.718,6.957,6.957,0,0,0-.246,2.823,5.968,5.968,0,0,0,6.138,5.033,5.892,5.892,0,0,0,4.858-2.823,4.046,4.046,0,0,0,.719-1.859c.175-3.139.105-6.261.123-9.4.018-7.067-.018-14.117.035-21.167Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 897.501, 4228.335)" fill="#3f3f3f"/>
      <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M43.956,4.939A5.641,5.641,0,0,0,39.987.944C36.486,0,22.447,0,22.447,0S8.408,0,4.907.944A5.641,5.641,0,0,0,.938,4.939C0,8.463,0,15.815,0,15.815s0,7.352.938,10.876a5.557,5.557,0,0,0,3.969,3.931c3.5.944,17.54.944,17.54.944s14.039,0,17.54-.944a5.557,5.557,0,0,0,3.969-3.931c.938-3.524.938-10.876.938-10.876s0-7.352-.938-10.876ZM17.856,22.49V9.14l11.734,6.675L17.856,22.49Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 976.605, 4183.69)" fill="#3f3f3f"/>
      <g transform="matrix(1, 0, 0, 1, 886.61, 4155.3)" filter="url(#Icon_awesome-linkedin)">
        <path id="Icon_awesome-linkedin-2" data-name="Icon awesome-linkedin" d="M29.184,0H2.238A2.255,2.255,0,0,0,0,2.266v26.9a2.255,2.255,0,0,0,2.238,2.266H29.184a2.261,2.261,0,0,0,2.245-2.266V2.266A2.261,2.261,0,0,0,29.184,0ZM9.5,26.939H4.841v-15H9.506v15ZM7.17,9.892a2.7,2.7,0,1,1,2.7-2.7,2.7,2.7,0,0,1-2.7,2.7ZM26.961,26.939H22.3v-7.3c0-1.74-.035-3.978-2.42-3.978-2.427,0-2.8,1.894-2.8,3.852v7.422H12.424v-15h4.469v2.049h.063a4.906,4.906,0,0,1,4.413-2.42c4.714,0,5.591,3.108,5.591,7.149Z" transform="translate(38.86 119.97) rotate(4)" fill="#3f3f3f"/>
      </g>
      <path id="Icon_awesome-spotify" data-name="Icon awesome-spotify" d="M17.674.563A17.674,17.674,0,1,0,35.348,18.237,17.68,17.68,0,0,0,17.674.563Zm7.177,26.005a1.36,1.36,0,0,1-.763-.257c-4.447-2.68-9.621-2.794-14.731-1.746a4.6,4.6,0,0,1-.848.185,1.11,1.11,0,0,1-1.126-1.126,1.129,1.129,0,0,1,.969-1.2c5.837-1.29,11.8-1.176,16.89,1.867a1.205,1.205,0,0,1,.691,1.176,1.077,1.077,0,0,1-1.083,1.1Zm1.917-4.675a1.76,1.76,0,0,1-.877-.3,23.1,23.1,0,0,0-17-2.1,2.935,2.935,0,0,1-.848.185A1.384,1.384,0,0,1,6.656,18.3a1.4,1.4,0,0,1,1.1-1.475,23.971,23.971,0,0,1,6.97-.969A24.947,24.947,0,0,1,27.345,19.1a1.486,1.486,0,0,1,.805,1.4A1.381,1.381,0,0,1,26.768,21.892Zm2.209-5.43a1.639,1.639,0,0,1-.919-.278c-5.074-3.029-14.146-3.756-20.019-2.117a3.385,3.385,0,0,1-.919.185,1.645,1.645,0,0,1-1.661-1.682,1.684,1.684,0,0,1,1.24-1.7,29.869,29.869,0,0,1,8.374-1.083c5.2,0,10.654,1.083,14.638,3.407A1.673,1.673,0,0,1,30.63,14.8,1.651,1.651,0,0,1,28.977,16.462Z" transform="translate(971.857 4274.702)" fill="#3f3f3f"/>
    </g>
  </svg>
</template>