<template>
  <div>
    <presenter></presenter>
    <logos></logos>
    <numbers></numbers>
    <advantages></advantages>
    <expertise></expertise>
    <!-- Calendly Inline-Widget Beginn -->
    <div style="margin-top: 40px">
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '60px 0 0px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Jetzt kostenfreies Kennenlernen vereinbaren
      </div>
      <div class="calendly-inline-widget" data-url="https://calendly.com/unvergessen/agentur-kennenlernen" style="min-width:320px;" :style="{ height: smAndDown ? '1200px' : '900px' }"></div>
      <div id="script-container">

      </div>
    </div>
    <!-- Calendly Inline-Widget Ende -->
    <about-us></about-us>
  </div>
</template>

<script>
import AboutUs from '@/components/home/AboutUsComponent.vue'
import Advantages from '@/components/home/AdvantagesComponent.vue'
import Expertise from '@/components/home/ExpertiseComponent.vue'
import Numbers from '@/components/landing-pages/AgenturNumbersComponent.vue'
import Presenter from '@/components/landing-pages/AgenturPresenterComponent.vue'
import Logos from '@/components/home/LogosComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, mdAndUp, smAndDown } = useDisplay()

    return { xs, mdAndUp, smAndDown }
  },
  mounted () {
    var el = document.getElementById('script-container')
    var script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.type = 'text/javascript'
    script.async = true
    el.appendChild(script)
  },
  components: {
    AboutUs,
    Advantages,
    Expertise,
    Numbers,
    Presenter,
    Logos
  }
}
</script>