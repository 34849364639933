<template>
  <div :style="{ padding: xs ? '20px 0px' : '20px' }" class="text-left">
    <v-form
      ref="downloadForm"
    >
      <div class="mb-4" v-if="error || errorAlreadyRegistered || success">
        <v-alert type="error" v-model="error">
          Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.<br>
          Sollte dieses Problem weiterhin bestehen, melden Sie sich bitte per Mail unter <a href="mailto:p.pelgen@unvergessen-agentur.de" target="_blank">p.pelgen@unvergessen-agentur.de</a>
        </v-alert>

        <v-alert type="error" v-model="errorAlreadyRegistered">
          Sie sind bereits mit {{ mail }} registriert. Wir haben Ihnen gerade eine weitere E-Mail mit Ihrem <b>kostenlosen Social Media Journal</b> geschickt.
        </v-alert>

        <!-- <v-alert type="success" color="primary" v-model="success">
          Vielen Dank für Ihr Interesse am Social Media Journal.<br>
          Wir haben Ihnen gerade eine E-Mail mit Ihrem Zugang zum Journal geschickt.
        </v-alert> -->
      </div>

      <!-- Name -->
      <v-text-field
        label="Name*"
        v-model="name"
        required
        :rules="rules"
      ></v-text-field>

      <!-- Mail -->
      <v-text-field
        label="E-Mail Adresse*"
        type="email"
        v-model="mail"
        required
        :rules="rules"
      ></v-text-field>

      <!-- Phone -->
      <v-text-field
        label="Telefon*"
        v-model="phone"
        required
        :rules="rules"
        type="tel"
      ></v-text-field>

      <div class="text-center">
        <v-btn color="primary" width="100%" min-height="50px" @click="optIn">Herunterladen</v-btn>
      </div>
    </v-form>

    <v-dialog v-model="success" max-width="500px" fullscreen="xs">
      <v-card>
        <div style="padding: 20px; text-align: center">
          <div style="padding: 10px;">
            <v-icon color="primary" size="160">mdi-check-circle</v-icon>
          </div>
          <div style="margin-bottom: 10px">
            Vielen Dank für Ihr Interesse am Social Media Journal.<br>
            Wir haben Ihnen gerade eine E-Mail mit Ihrem Zugang zum Journal geschickt.
          </div>

          <div>
            <v-btn color="primary" @click="success = false" style="width: 60%; padding: 4px; text-transform: none">
              Schließen
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'
import axios from 'axios'

export default {
  setup () {
    const { xs } = useDisplay()

    return { xs }
  },
  data () {
    return {
      name: '',
      mail: '',
      phone: '',
      success: false,
      rules: [
        v => !!v || 'Dieses Feld wird benötigt'
      ],
      // ----------- Errors --------------
      errorAlreadyRegistered: false,
      error: false
    }
  },
  methods: {
    async optIn () {
      this.success = false
      this.error = false
      this.errorAlreadyRegistered = false
      const { valid } = await this.$refs.downloadForm.validate()

      if (valid) {
        axios.post('/agentur/subscribe', { name: this.name , mail: this.mail, phone: this.phone})
          .then(() => {
            this.success = true
            this.reset()
          })
          .catch(error => {
            this.success = false
            if (error.response.status === 409) {
              this.errorAlreadyRegistered = true
            } else {
              this.error = true
            }
            this.resetValidation()
          })
      }
    },
    reset () {
      this.$refs.downloadForm.reset()
    },
    resetValidation () {
      this.$refs.downloadForm.resetValidation()
    },
  }
}
</script>
