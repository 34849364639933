<template>
  <div>
    <div :class="{ 'about-us': mdAndUp, 'about-us-mobile': smAndDown }">
      <div class="main">
        <home-container style="position: relative; height: 100%">
          <div class="font-weight-medium text-white" :style="{ 'margin': mdAndUp ? '0  0 80px 0' : '0' }" :class="{ 'text-h2': mdAndUp, 'text-h4': smAndDown }">
            Warum wir?
          </div>

          <div style="position: absolute; bottom: 0; left: 0; width: 100%; padding: 60px 0" :style="{ padding: lgAndUp ? '60px 0' : '20px 0' }" v-if="smAndUp">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <p class="text-white" :class="{ 'text-h4': lgAndUp, 'text-h5': md }">
                    Über 25 Jahre<br>
                    <span class="text-primary-dark font-weight-medium">
                      Trauer- & Social<br>
                      Media Erfahrung
                    </span>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="text-white" :class="{ 'text-h4': lgAndUp, 'text-h5': md }">
                    Spezialisierung auf<br>
                    <span class="text-primary-dark font-weight-medium">
                      Social Media für<br>
                      Bestattungsinstitute
                    </span>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="text-white" :class="{ 'text-h4': lgAndUp, 'text-h5': md }">
                    Kreative, empathische<br>
                    <span class="text-primary-dark font-weight-medium">
                      und pietätvolle<br>
                      Kommunikation
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </home-container>
      </div>
    </div>

    <div class="py-6 mobile-second" style="margin-top: -10px" v-if="xs">
      <v-container>
        <v-row>
          <v-col cols="12">
            <p class="text-white text-h6 font-weight-regular" style="line-height: 24px">
              Über 25 Jahre<br>
              <span class="text-primary-dark font-weight-bold">
                Trauer- & Social<br>
                Media Erfahrung
              </span>
            </p>
          </v-col>
          <v-col cols="12">
            <p class="text-white text-h6 font-weight-regular" style="line-height: 24px">
              Spezialisierung auf<br>
              <span class="text-primary-dark font-weight-bold">
                Social Media für<br>
                Bestattungsinstitute
              </span>
            </p>
          </v-col>
          <v-col cols="12">
            <p class="text-white text-h6 font-weight-regular" style="line-height: 24px">
              Kreative, empathische<br>
              <span class="text-primary-dark font-weight-bold">
                und pietätvolle<br>
                Kommunikation
              </span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, md, smAndUp, mdAndUp, lgAndUp, smAndDown } = useDisplay()

    return { xs, md, smAndUp, mdAndUp, lgAndUp, smAndDown }
  },
  components: {
    HomeContainer
  }
}
</script>

<style scoped>
.about-us {
  background-image: url('@/assets/home/Unvergessen_Agentur_Team_Geschäftsführung.jpg');
  background-size: contain;
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0 auto
}

.about-us::after {
  padding-top: 46.875%;
  display: block;
  content: ''
}

.about-us-mobile {
  background-image: url('@/assets/home/Unvergessen_Agentur_Team_Geschäftsführung_m.jpg');
  background-size: contain;
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0 auto
}

.about-us-mobile::after {
  padding-top: 51.39%;
  display: block;
  content: ''
}

.main {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.mobile-second {
  background-image: url('@/assets/home/Unvergessen_Agentur_Sarg_Bestatter_m_2.jpg');
  background-size: cover;
  background-position: center
}
</style>
