<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="163.593" height="143.909" viewBox="0 0 163.593 143.909">
    <defs>
      <filter id="Icon_metro-pencil" x="0" y="51.316" width="92.593" height="92.593" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Gruppe_896" data-name="Gruppe 896" transform="translate(-303.204 -3507)">
      <path id="Icon_awesome-camera" data-name="Icon awesome-camera" d="M114.2,27.231V91.468a10.709,10.709,0,0,1-10.706,10.706H10.706A10.709,10.709,0,0,1,0,91.468V27.231A10.709,10.709,0,0,1,10.706,16.525H30.334l2.743-7.338A10.691,10.691,0,0,1,43.092,2.25H71.084A10.691,10.691,0,0,1,81.1,9.187l2.766,7.338h19.628A10.709,10.709,0,0,1,114.2,27.231ZM83.865,59.349A26.765,26.765,0,1,0,57.1,86.115,26.787,26.787,0,0,0,83.865,59.349Zm-7.137,0A19.628,19.628,0,1,1,57.1,39.721,19.657,19.657,0,0,1,76.727,59.349Z" transform="translate(352.598 3504.75)" fill="#3f3f3f"/>
      <g transform="matrix(1, 0, 0, 1, 303.2, 3507)" filter="url(#Icon_metro-pencil)">
        <path id="Icon_metro-pencil-2" data-name="Icon metro-pencil" d="M14.226,1.928A11.655,11.655,0,0,0,4.9,20.576l4.662,4.662L25.881,8.921,21.219,4.259A11.6,11.6,0,0,0,14.226,1.928ZM72.5,55.542l4.662,20.979L56.184,71.859,13.06,28.735,29.378,12.418ZM25.038,28.413,57.672,61.047l4.018-4.018L29.055,24.395Z" transform="translate(6.43 55.39)" fill="#3f3f3f"/>
      </g>
    </g>
  </svg>
</template>