<template>
  <div style="padding: 20px">
    <div style="max-width: 1600px; margin: auto">
      <v-container>
        <v-row>
          <v-col cols="2">
            <v-img
              class="logo"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://unvergessen-agentur.de/bestattungskultur-07-08-2023.pdf')"
              src="@/assets/home/logos/BestattungskulturLogo.png"
            ></v-img>
          </v-col>
          <v-col cols="2">
            <v-img
              class="logo"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://unvergessen-agentur.de/Unvergessen_Agentur_Bundesverband_Bestattungsbedarf.pdf')"
              src="@/assets/home/logos/Bundesverband_Bestattungsbedarf.jpg"
            ></v-img>
          </v-col>
          <v-col cols="2">
            <v-img
              class="logo"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://www.horizont.net/agenturen/nachrichten/unvergessen-das-ist-die-erste-social-media-agentur-fuer-bestattungsunternehmen-201686')"
              src="@/assets/home/logos/Horizont.png"
            ></v-img>
          </v-col>
          <v-col cols="2">
            <v-img
              class="logo"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://www.absatzwirtschaft.de/unvergessen-agentur-so-funktioniert-social-media-fuer-bestattungshaeuser-241093/')"
              src="@/assets/home/logos/Absatzwirtschaft.png"
            ></v-img>
          </v-col>
          <v-col cols="2">
            <v-img
              class="logo-omr"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://omr.com/de/daily/unvergessen-startup-sterbebranche-digitalisieren/')"
              src="@/assets/home/logos/OMR.png"
            ></v-img>
          </v-col>
          <v-col cols="2">
            <v-img
              class="logo"
              :style="{ width: xs ? '100%' : '60%' }"
              @click="pushTo('https://www.pr-journal.de/nachrichten/agenturen/29236-dieser-mann-macht-social-media-fuer-bestattungsinstitute.html')"
              src="@/assets/home/logos/PR_Journal.jpg"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs } = useDisplay()

    return { xs }
  },
  methods: {
    pushTo (target) {
      this.$ga.event('Home', 'Click_on_Logo', target, 0)
      window.open(target, '_blank')
    }
  }
}
</script>

<style scoped>
.logo {
  margin: auto;
  height: 97%;
  filter: grayscale(1) opacity(70%);
  transition: all 0.2s ease;
}

.logo-omr {
  margin: auto;
  height: 95%;
  filter: grayscale(1) opacity(70%);
  transition: all 0.2s ease;
  cursor: pointer
}

.logo:hover {
  filter: none;
  width: 65% !important;
  height: 100%;
  cursor: pointer
}

.logo-omr:hover {
  filter: none;
  height: 100%;
}
</style>
