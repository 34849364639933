// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import ChartIcon from '@/assets/customicons/ChartIcon.vue'
import PeopleIcon from '@/assets/customicons/PeopleIcon.vue'
import TargetIcon from '@/assets/customicons/TargetIcon.vue'
import LogoCandleIcon from '@/assets/customicons/LogoCandleIcon.vue'
import SocialsIcon from '@/assets/customicons/SocialsIcon.vue'
import EditPhotoIcon from '@/assets/customicons/EditPhotoIcon.vue'
import PaperPlaneIcon from '@/assets/customicons/PaperPlaneIcon.vue'
import ChatIcon from '@/assets/customicons/ChatIcon.vue'
import WelcomeIcon from '@/assets/customicons/WelcomeIcon.vue'
import FeedbackIcon from '@/assets/customicons/FeedbackIcon.vue'
import PartnershipIcon from '@/assets/customicons/PartnershipIcon.vue'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        variables: {},
        colors: {
          primary: '#007656', // '#f8ac5b',
          'primary-dark': '#00B484',
          'primary-hover': '#eb9234',

          secondary: '#f7eee6',
          'secondary-hover': '#ebd9c7',

          btnsPage: '#f9dabe',
          text: '#404040',

          orange: '#fba346',
          orange2: '#e19c53',
          orange3: '#febf7c',
          orange4: '#fbcc9a',

          grey: '#bbbbbb',
          grey2: '#d0d0d0',
          grey3: '#e8e8e8',
          grey4: '#fafafa',

          beige: '#eee3d8',

          brown: '#835637',
          brown2: '#9e653e',
          brown3: '#a35418',
          brown4: '#cc7a3b',

          skin: '#bf8e75',
          skin2: '#d3a489',
          skin3: '#eeb08f',
          skin4: '#eab599',
          skin5: '#f6caae',

          gold: '#db9d5c',
          gold2: '#e1a560',
          gold3: '#fbd879',
          gold4: '#fbe096',

          green: '#79c28c',
          green2: '#90dca3',

          red: '#f14242',
          red2: '#f55858',

          success: '#24bf77', // '#47DA6C',
          error: '#e10000'
        }
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    aliases: {
      chart: ChartIcon,
      people: PeopleIcon,
      target: TargetIcon,
      'logo-candle': LogoCandleIcon,
      chat: ChatIcon,
      'paper-plane': PaperPlaneIcon,
      'edit-photo': EditPhotoIcon,
      socials: SocialsIcon,
      welcome: WelcomeIcon,
      feedback: FeedbackIcon,
      partnership: PartnershipIcon
    }
  }
})
