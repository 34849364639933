<template>
  <div
    class="expertise-card"
    style="max-width: 400px; margin: auto; height: 100%;  position: relative"
    :style="{ padding: lgAndUp ? '40px 50px' : '40px', 'min-height': mdAndUp ? '490px;' : '' }"
  >
    <div class="line" v-if="special"></div>
    <v-icon :icon="icon" :size="lgAndUp ? 130 : md ? 100 : smAndDown && icon !== '$logo-candle' ?  70 : 40" />
    <div
      class="text-primary font-weight-medium"
      :class="{ 'text-h4': lgAndUp, 'text-h5': md, 'text-h6': smAndDown }"
      :style="{ margin: mdAndUp ? '30px 0' : smAndDown && icon !== '$logo-candle' ? '10px 0 20px 0' : '40px 0 20px 0' }"
    >
      {{ headline }}
    </div>
    <div :style="{ 'font-size': lgAndUp ? '22px' : '' }" class="font-weight-regular">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { smAndUp, sm, md, mdAndUp, lgAndUp, smAndDown } = useDisplay()

    return { smAndUp, sm, md, mdAndUp, lgAndUp, smAndDown }
  },
  props: ['icon', 'headline', 'special']
}
</script>

<style scoped>
.expertise-card {
  border-style: solid;
  border-color: var(--v-text-base)
}

.line {
  width: 112px;
  height: 47px;
  border-bottom: 2px solid black;
  -webkit-transform:
      rotate(45deg); 
  position: absolute;
  top: -1px;
  right: -34px
  /* top: -20px; */
}
</style>
