<template>
  <div :style="{ padding: smAndDown ? '15px' : '100px' }">
    <div style="position: relative; max-width: 1200px; margin: auto" class="text-left" v-if="!success">
      <v-alert
        v-model="error"
        type="error"
        style="max-width: 1200px; width: 100%"
        :class="{ alert: !smAndDown }"
      >
        Leider konnte Ihre Nachricht nicht gesendet werden. Bitte versuchen Sie es erneut.
      </v-alert>
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <div class="font-weight-medium" :class="{ 'text-h4': !smAndDown, 'text-h5': smAndDown }">
              Wir freuen uns,<br>Sie kennen zu lernen!
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Name"
                variant="outlined"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-Mail-Adresse"
                variant="outlined"
                lazy-validate
                required
              ></v-text-field>

              <v-textarea
                v-model="message"
                label="Nachricht"
                variant="outlined"
              ></v-textarea>

               <v-btn
                  color="primary"
                  @click="validate"
                  style="text-transform: none"
                >
                  Nachricht senden
                </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="font-weight-medium" :class="{ 'text-h4': !smAndDown, 'text-h5': smAndDown }" style="max-width: 1000px; margin: auto" :style="{ padding: smAndDown ? '15px' : '100px' }" v-else>
      <div>
        Vielen Dank für Ihre Nachricht.<br>
        Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.
      </div>
      <v-btn
        class="mt-8"
        color="primary"
        @click="$router.push({ name: 'Home' })"
        style="text-transform: none"
      >
        Zurück zur Website
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'
import axios from 'axios'

export default {
  data () {
    return {
      success: false,
      error: false,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Ihr Name ist ein Pflichtfeld'
      ],
      email: '',
      emailRules: [],
      message: ''
    }
  },
  setup () {
    const { smAndDown } = useDisplay()

    return { smAndDown }
  },
  methods: {
    validate () {
      this.emailRules = [
        v => !!v || 'Die E-Mail-Adresse ist ein Pflichtfeld',
        v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine korrekte E-Mail-Adresse ein',
      ]
      this.$refs.form.validate().then(res => {
        if (res.valid) {
          var params = {
            name: this.name,
            email: this.email,
            text: this.message
          }
          axios.post('/agentur/contact', params)
            .then(() => {
              this.$ga.event('Contact', 'Message_sent', params, 0)
              this.success = true
              this.error = false
              this.reset()
            })
            .catch(err => {
              console.log(err)
              this.$ga.event('Contact', 'Message_failed', err.message, 0)
              this.success = false
              this.error = true
              var self = this
              setTimeout(() => {
                self.error = false
              }, 4000);
            })
        }
      })
    },
    reset () {
      this.emailRules = []
      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>
.alert {
  position: absolute;
  top: -75px;
  left: 0;

}
</style>
