<template>
  <v-app-bar
    color="white"
    class="unvergessen-shadow"
    :style="{ padding: mdAndUp ? '0 90px' : '' }"
    height="80"
  >
    <v-app-bar-title>
      <v-img
        src="@/assets/Unvergessen_Agentur_Logo_grau.png"
        alt="Unvergessen Agentur Logo"
        max-width="150px"
        @click="$router.push({ name: 'Home' })"
        style="cursor: pointer;"
      />
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <span style="margin-right: 40px; font-size: 20px; cursor: pointer" v-if="mdAndUp" @click="phone">
      <v-icon icon="mdi-phone" size="22"></v-icon> 0681 965 938 85
    </span>
    <span style="margin-right: 40px; font-size: 20px">|</span>
    <v-btn
      variant="flat"
      color="primary"
      style="text-transform: none; font-size: 16px"
      height="50px"
      :to="{ name: 'Contact' }"
      @click="$ga.event('NavBar', 'Click_on_Contact_Button', '', 0)"
      v-if="mdAndUp"
    >
      Kontakt
    </v-btn>
    <div v-else>
      <v-icon color="text" class="mr-4" size="30" @click="mailTo()">
        mdi-email
      </v-icon>
      <v-icon color="text" size="30" @click="phone()">
        mdi-phone
      </v-icon>
      <!-- |
      <span style="width: 100%; cursor: pointer" class="pl-8">Anmelden</span> -->
    </div>
  </v-app-bar>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { smAndDown, mdAndUp } = useDisplay()

    return { smAndDown, mdAndUp }
  },
  methods: {
    mailTo () {
      window.open('mailto://p.pelgen@unvergessen-agentur.de')
    },
    phone () {
      window.open("tel:+49 681 965 938 85")
    }
  }
}
</script>
