<template>
  <div>
    <presenter></presenter>
    <logos></logos>
    <info></info>
    <numbers></numbers>
    <advantages></advantages>
    <expertise></expertise>
    <cooperation></cooperation>
    <about-us></about-us>
  </div>
</template>

<script>
import AboutUs from '@/components/home/AboutUsComponent.vue'
import Advantages from '@/components/home/AdvantagesComponent.vue'
import Cooperation from '@/components/home/CooperationComponent.vue'
import Expertise from '@/components/home/ExpertiseComponent.vue'
import Info from '@/components/home/InfoComponent.vue'
import Numbers from '@/components/home/NumbersComponent.vue'
import Presenter from '@/components/home/PresenterComponent.vue'
import Logos from '@/components/home/LogosComponent.vue'

export default {
  components: {
    AboutUs,
    Advantages,
    Cooperation,
    Expertise,
    Info,
    Numbers,
    Presenter,
    Logos
  }
}
</script>
