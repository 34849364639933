<template>
  <div>
    <home-container>
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 60px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Das sind wir
      </div>

      <div>
        <v-container>
          <v-row>
            <!-- Mobile Image -->
            <v-col cols="12" v-if="smAndDown">
              <v-img style="border-radius: 30px" src="@/assets/home/Unvergessen_Agentur_Team.jpg"></v-img>
            </v-col>

            <!-- Text -->
            <v-col cols="12" md="7" class="text-left">
              <div class="text-h5">
                <v-container>
                  Gebündeltes Social-Media Know-How speziell für Ihr Bestattungsunternehmen
                </v-container>
              </div>
              <div style="padding: 20px 0;">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="d-table mb-6">
                        <div class="d-table-cell pa-4" style="vertical-align: middle">
                          <v-icon size="40px" color="primary">mdi-thumb-up</v-icon>
                        </div>
                        <div class="d-table-cell">
                          <p :class="{ 'text-h5': xl }" class="font-weight-regular">
                            Über 25 Jahre<br>
                            <span class="text-primary font-weight-bold">
                              Trauer- & Social<br>
                              Media Erfahrung
                            </span>
                          </p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="d-table mb-6">
                        <div class="d-table-cell pa-4" style="vertical-align: middle">
                          <v-icon size="40px" color="primary">mdi-thumb-up</v-icon>
                        </div>
                        <p :class="{ 'text-h5': xl }" class="font-weight-regular">
                          Spezialisierung auf<br>
                          <span class="text-primary font-weight-bold">
                            Social Media für<br>
                            Bestattungsinstitute
                          </span>
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="d-table mb-6">
                        <div class="d-table-cell pa-4" style="vertical-align: middle">
                          <v-icon size="40px" color="primary">mdi-thumb-up</v-icon>
                        </div>

                        <p :class="{ 'text-h5': xl }" class="font-weight-regular">
                          Kreative, empathische<br>
                          <span class="text-primary font-weight-bold">
                            und pietätvolle<br>
                            Kommunikation
                          </span>
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="d-table mb-6">
                        <div class="d-table-cell pa-4" style="vertical-align: middle">
                          <v-icon size="40px" color="primary">mdi-thumb-up</v-icon>
                        </div>
                        <p :class="{ 'text-h5': xl }" class="font-weight-regular">
                          Deutschland erste<br>
                          <span class="text-primary font-weight-bold">
                             Bestatter Social<br>
                             Media Agentur
                          </span>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>

            <!-- Desktop Image -->
            <v-col cols="5" v-if="mdAndUp">
              <v-img style="border-radius: 30px" src="@/assets/home/Unvergessen_Agentur_Team.jpg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </home-container>
  </div>
</template>


<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { mdAndUp, smAndDown, xl } = useDisplay()

    return { mdAndUp, smAndDown, xl }
  },
  components: {
    HomeContainer
  }
}
</script>

