<template>
  <div :style="{ padding: xs ? '140px 0' : '' }">
    <home-container>
      <div v-if="!key">
        <v-icon color="error" size="160">mdi-alert</v-icon><br>
        Leider ist etwas schiefgelaufen. Bitte klicken Sie erneut auf den Link in Ihrer erhaltenen E-Mail.<br>
        Sollte dieses Problem weiterhin bestehen, kontaktieren Sie uns bitte unter <a href="mailto:p.pelgen@unvergessen-agentur.de" target="_blank">p.pelgen@unvergessen-agentur.de</a>
      </div>
      <div v-else>
        <div style="padding: 0 0 20px">
          <v-icon color="primary" size="160">mdi-check-circle</v-icon>
        </div>
        <v-alert type="error" v-model="error">
          Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.
        </v-alert>
        <v-alert type="error" v-model="errorNotAuthorized">
          Sie sind leider nicht berechtigt, das Social Media Journal herunterzuladen. Bitte klicken Sie erneut auf den Link in Ihrer erhaltenen E-Mail und versuchen Sie es erneut.<br>
          Sollte dieses Problem weiterhin bestehen, kontaktieren Sie uns bitte unter <a href="mailto:p.pelgen@unvergessen-agentur.de" target="_blank">p.pelgen@unvergessen-agentur.de</a>
        </v-alert>
        <p v-if="!error && !errorNotAuthorized">
          Sie werden automatisch zum Social Media Journal weitergeleitet. Sollte dies nicht der Fall sein, klicken Sie bitte <span style="cursor: pointer; color: blue" @click="download">hier</span>.
        </p>
      </div>

      <!-- Calendly Inline-Widget Beginn -->
      <div style="margin-top: 50px">
        <h2 :style="{ 'margin-bottom': xs ? '0px' : '-40px' }" :class="{ 'text-h6': xs }">
          Hier können Sie ein kostenfreies Kennenlernen vereinbaren
        </h2>
        <div class="calendly-inline-widget" data-url="https://calendly.com/unvergessen/agentur-kennenlernen" style="min-width:320px;" :style="{ height: smAndDown ? '1400px' : '900px' }"></div>
        <div id="script-container">

        </div>
      </div>
      <!-- Calendly Inline-Widget Ende -->
    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import axios from 'axios'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, smAndDown } = useDisplay()

    return { xs, smAndDown }
  },
  data () {
    return {
      errorNotAuthorized: false,
      error: false
    }
  },
  methods: {
    download () {
      this.error = false
      this.errorNotAuthorized = false

      axios.get('/agentur/journal', { params: { key: this.key } })
        .then(() => {
          window.open('https://unvergessen-agentur.de/Social_Media_Journal_Unvergessen_Agentur.pdf', '_blank')
        })
        .catch(error => {
          console.log(error.response.status)
          if (error.response.status === 404) {
            this.errorNotAuthorized = true
          } else {
            this.error = true
          }
        })
    }
  },
  mounted () {
    this.download()
    var el = document.getElementById('script-container')
    var script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.type = 'text/javascript'
    script.async = true
    el.appendChild(script)
  },
  computed: {
    key () {
      return this.$route.query.key
    }
  },
  components: {
    HomeContainer
  }
}
</script>
