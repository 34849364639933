<template>
  <div
    class="bg-secondary text-text text-left"
    :style="{ 'font-size': lgAndUp ? '45px' : md ? '30px' : sm ? '25px' : '20px', 'line-height': lgAndUp ? '50px' : md ? '35px' : sm ? '30px' : '25px' }"
  >
    <home-container>
      Durch individuelle Social Media Betreuung unterstützen wir Bestattungsunternehmen im Auf- und Ausbau Ihrer Bekanntheit.
    </home-container>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'
import HomeContainer from '@/components/home/HomeContainerComponent.vue'

export default {
  setup () {
    const { sm, mdAndUp, lgAndUp, md } = useDisplay()

    return { sm, mdAndUp, lgAndUp, md }
  },
  components: {
    HomeContainer
  }
}
</script>
