<template>
  <div :style="{ padding: smAndDown ? '5%' : '3% 15%' }" class="text-left">
    <h1>Impressum</h1><br>
    <h2>Kontakt</h2>
    <p>
      Wir freuen uns über Ihre Kontaktaufnahme. Drücken Sie dazu bitte auf 
      <a class="text-primary" href="/Kontakt" style="cursor: pointer">Kontaktaufnahme</a>.
    </p>
    <br>
    <h2>
      unvergessen-agentur.de wird betrieben von:
    </h2>
    <p>
      eternio – Gesellschaft für Erinnerungskultur mbH<br>
      Campus, Gebäude A1.1<br>
      66123 Saarbrücken<br>
      Deutschland<br>
      <br>
      Telefon: 0681 965 938 85<br>
      E-Mail: p.pelgen@unvergessen-agentur.de<br>
      <br>
      Vertretungsberechtigte Geschäftsführer: Henrik Bergmann, Max Jakob, Philip Pelgen und Felix Wenzel<br>
      <br>
      Umsatzsteuer-Identifikationsnummer: DE330483463<br>
      Handelsregister-Nr. und Ort: Amtsgericht Saarbrücken HRB 106569<br><br>
      Inhaltlich Verantwortlicher gemäß § 55 II RStV:<br>
      Philip Pelgen, Campus, Gebäude A1.1, 66123 Saarbrücken, Deutschland<br>
    </p>
    <br>
    <h3>
      Verbraucherstreitbeilegung
    </h3>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit, die du unter <a target="_blank" href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> findest. Die OS-Plattform soll als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen, dienen. eternio nimmt nicht an dem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.
    </p>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { smAndDown } = useDisplay()

    return { smAndDown }
  }
}
</script>
