<template>
  <div
    class="text-white"
    :class="{ 'advantages': smAndUp, 'advantages-m': xs }"
  >
    <home-container>
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 60px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Was Sie erhalten
      </div>

      <div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              :class="{ 'text-h4': lgAndUp, 'text-h5': md, 'text-h6': smAndDown }"
              :style="{ 'line-height': smAndDown ? '24px' : '' }"
              class="font-weight-regular"
            >
              <v-icon icon="$chart" :size="mdAndUp ? 130 : 80" :class="{ 'mb-8': mdAndUp, 'mb-2': smAndDown }" /><br>
              Sie erhalten mehr<br>
              <span class="text-primary-dark font-weight-bold">
                Aufträge
              </span>
            </v-col>  
            <v-col
              cols="12"
              sm="4"
              :class="{ 'text-h4': lgAndUp, 'text-h5': md, 'text-h6': smAndDown }"
              :style="{ 'line-height': smAndDown ? '24px' : '' }"
              class="font-weight-regular"
            >
              <v-icon icon="$people" :size="mdAndUp ? 130 : 80" :class="{ 'mb-8': mdAndUp, 'mb-2': smAndDown }" /><br>
              Sie finden einfacher<br>
              <span class="text-primary-dark font-weight-bold">
                Mitarbeiter
              </span>
            </v-col>  
            <v-col
              cols="12"
              sm="4"
              :class="{ 'text-h4': lgAndUp, 'text-h5': md, 'text-h6': smAndDown }"
              :style="{ 'line-height': smAndDown ? '24px' : '' }"
              class="font-weight-regular"
            >
              <v-icon icon="$target" :size="mdAndUp ? 130 : 80" :class="{ 'mb-8': mdAndUp, 'mb-2': smAndDown }" /><br>
              Sie behalten Ihren<br>
              <span class="text-primary-dark font-weight-bold">
                Fokus
              </span>
            </v-col>  
          </v-row>
        </v-container>

        <div style="padding: 5% 40px 0; max-width: 700px; margin: auto">
          <v-btn color="primary" width="100%" min-height="50px" @click="optIn">Herunterladen</v-btn>
        </div>
      </div>
    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, md, smAndUp, mdAndUp, lgAndUp, smAndDown } = useDisplay()

    return { xs, md, smAndUp, mdAndUp, lgAndUp, smAndDown }
  },
  methods: {
    optIn () {
      this.$router.push({ name: 'DownloadPage' })
    }
  },
  components: {
    HomeContainer
  }
}
</script>

<style scoped>

.advantages {
  background-image: url('@/assets/home/Unvergessen_Agentur_Sarg_Bestatter.jpg');
  background-size: cover;
  background-position: center
}

.advantages-m {
  background-image: url('@/assets/home/Unvergessen_Agentur_Sarg_Bestatter_m.jpg');
  background-size: cover;
  background-position: center
}
</style>
