<template>
  <div
    :class="{ expertise: smAndUp, 'expertise-m': xs }"
  >
    <home-container>
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 80px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Alles aus einer Hand
      </div>

      <v-container>
        <v-row>
          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="$logo-candle"
              headline="Verständnis der Zielgruppe"
              special="true"
            >
              Über 25 Jahre Trauer- und Social Media Erfahrung. Reiner Fokus auf Bestattungsinstitute.
            </expertise-card>
          </v-col>

          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="mdi-share-variant"
              headline="Garantierte Reichweiten"
            >
              Sie erreichen mind. 10.000 Menschen pro Monat in einer für Sie relevanten Zielgruppe
            </expertise-card>
          </v-col>

          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="$paper-plane"
              headline="Strategie & Konzept"
            >
              Entwicklung eines konkreten und umsetzbaren Fahrplans, inkl. durchgehender Beratung
            </expertise-card>
          </v-col>
        </v-row>

        <v-row :style="{ 'margin-top': mdAndUp ? '40px' : '14px' }">
          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="$edit-photo"
              headline="Inhalte finden & produzieren"
            >
              Komplettes Social Media Management inklusive Redaktionsplanung
            </expertise-card>
          </v-col>

          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="$socials"
              headline="Betreuung der Kanäle"
            >
              Facebook, Instagram, TikTok, LinkedIn, Podcast, YouTube, WhatsApp, Pinterest
            </expertise-card>
          </v-col>
          
          <v-col cols="12" sm="4" class="d-flex" style="flex-direction:column">
            <expertise-card
              icon="$chat"
              headline="Moderation & Kommunikation"
            >
              Intensive 24/7 Betreuung und Vermeidung negativer Kommentare
            </expertise-card>
          </v-col>
        </v-row>
      </v-container>

    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import ExpertiseCard from '@/components/home/expertise/ExpertiseCard.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, sm, smAndUp, mdAndUp, smAndDown } = useDisplay()

    return { xs, sm, smAndUp, mdAndUp, smAndDown }
  },
  components: {
    HomeContainer,
    ExpertiseCard
  }
}
</script>

<style scoped>
.expertise {
  background-image: url('@/assets/home/Unvergessen_Agentur_Leistungen_Blumen_Bestattung.jpg');
  background-size: cover;
  background-position: center
}

.expertise-m {
  background-image: url('@/assets/home/Unvergessen_Agentur_Leistungen_Blumen_Bestattung_m.jpg');
  background-size: cover;
  background-position: center
}
</style>
