<template>
  <div>
    <v-app class="text-text">
      <nav-bar></nav-bar>
      <v-main style="margin-bottom: -10px">
         <router-view/>
      </v-main>
      <footer-comp></footer-comp>
    </v-app>
  </div>
</template>

<script>
import NavBar from '@/components/global/NavBar.vue'
import FooterComp from '@/components/global/FooterComponent.vue'

require('@/css/globalStyles.css')

export default {
  components: {
    NavBar,
    FooterComp
  }  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
