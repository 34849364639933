<template>
  <div style="padding: 100px">
    <div style="max-width: 1000px; margin: auto">
      <h1>
        Jetzt unser <b>kostenloses Social-Media Journal</b> herunterladen
      </h1>
      <div style="padding: 40px">
        <download-form></download-form>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadForm from '@/components/journal-page/forms/DownloadFormComponent.vue'
export default {
  components: {
    DownloadForm
  },
  created () {
    window.scrollTo(0,0);
  }
}
</script>
