<template>
  <div :style="{ padding: smAndDown ? '5%' : '3% 15%' }" class="text-left">
    <h1 style="margin-bottom: 20px">Datenschutzerklärung für den Internetauftritt www.unvergessen-agentur.de</h1>

    <h2>I. Allgemeines</h2>
    <p>
      Wir, die eternio – Gesellschaft für Erinnerungskultur mbH, Campus, Starterzentrum, Gebäude A 1.1, 66123 Saarbrücken mit unseren Geschäftsführern Henrik Bergmann, Max Jakob und Philip Pelgen, legen großen Wert auf den Schutz Ihrer personenbezogenen Daten, wenn Sie unseren Internetauftritt www.unvergessen-agentur.de nutzen. Nachfolgend möchten wir Sie daher ausführlich darüber informieren, welche Daten wir von Ihnen bei Ihrem Besuch unseres Internetauftritts und der Nutzung unserer dortigen Angebote erheben und wie diese von uns im Folgenden verarbeitet oder genutzt werden und welche Rechte Ihnen insoweit zustehen.
    </p>
    <p>
      Ihre personenbezogenen Daten werden von uns dabei nur auf der Basis des gesetzlichen Datenschutzrechts, also der EU-Datenschutzgrundverordnung (DSGVO), dem Bundesdatenschutzgesetz (BDSG-neu) und dem Telemediengesetz (TMG) verarbeitet.
    </p>
    <p>
      Der Umfang der von uns erhobenen und verarbeiteten Daten unterscheidet sich danach, ob Sie nur unseren Internetauftritt zum Abruf von Informationen besuchen oder auch von uns über unsere Internetseite angebotene Leistungen in Anspruch nehmen.
    </p>

    <h2>
      II. Verantwortlicher
    </h2>
    <p>
      Der Verantwortliche im Sinne der Datenschutz-Grundverordnung sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
    </p>
    <p>
      <b>
        eternio – Gesellschaft für Erinnerungskultur mbH,<br>
        vertreten durch die Geschäftsführer Henrik Bergmann, Max Jakob, Philip Pelgen und Felix Wenzel
      </b><br>
      Campus, Starterzentrum, Gebäude A 1.1,<br>
      66123 Saarbrücken<br>
      Telefon: 0681 965 938 85<br>
      E-Mail: p.pelgen@unvergessen-agentur.de
    </p>

    <h2>
      III. Begriffsbestimmungen
    </h2>
    <p>
      Unsere Datenschutzerklärung nutzt die Begrifflichkeiten der EU-Datenschutzgrundverordnung (DSGVO), die wir für Sie zum einfacheren Verständnis kurz erläutern möchten. Diese und weitere Begriffsbestimmungen finden Sie in Art. 4 DSGVO.
    </p>
    <h3>
      1. Personenbezogene Daten
    </h3>
    <p>
      „personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
    </p>
    <h3>
      2. betroffene Person
    </h3>
    <p>
      „betroffene Person“ ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
    </p>
    <h3>
      3. Verarbeitung
    </h3>
    <p>
      „Verarbeitung“ meint jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
    </p>
    <h3>
      4. Einschränkung der Verarbeitung
    </h3>
    <p>
      „Einschränkung der Verarbeitung“ meint die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
    </p>
    <h3>
      5. Pseudonymisierung
    </h3>
    <p>
      „Pseudonymisierung“ ist die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
    </p>
    <h3>
      6. Verantwortlicher
    </h3>
    <p>
      „Verantwortlicher“ ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so können der Verantwortliche beziehungsweise die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
    </p>
    <h3>
      7. Auftragsverarbeiter
    </h3>
    <p>
      „Auftragsverarbeiter“ meint eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
    </p>
    <h3>
      8. Empfänger
    </h3>
    <p>
      „Empfänger“ ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, denen personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger; die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im Einklang mit den geltenden Datenschutzvorschriften gemäß den Zwecken der Verarbeitung.
    </p>
    <h3>
      9. Dritter
    </h3>
    <p>
      „Dritter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
    </p>
    <h3>
      10. Einwilligung
    </h3>
    <p>
      „Einwilligung“ der betroffenen Person ist jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
    </p>

    <h2>
      IV. Allgemeine Hinweise zur Datenverarbeitung
    </h2>
    <h3>
      1. Kategorien von personenbezogenen Daten
    </h3>
    <p>
      Wir verarbeiten folgende Kategorien von personenbezogenen Daten:
    </p>
    <ul style="padding: 0 40px">
      <li>
        Bestandsdaten (z.B. Namen, Adressen, Funktionen, Organisationszugehörigkeit etc.);
      </li>
      <li>
        Kontaktdaten (z.B. E-Mail, Telefonnummern etc.);
      </li>
      <li>
        Inhaltsdaten (z.B. Texteingaben, Bilddateien, Videos etc.);
      </li>
      <li>
        Nutzungsdaten (z.B. Zugriffsdaten);
      </li>
      <li>
        Meta-/Kommunikationsdaten (z.B. IP-Adressen).
      </li>
    </ul>
    <h3>
      2. Empfänger bzw. Kategorien von Empfängern von personenbezogener Daten
    </h3>
    <p>
      Sofern wir im Rahmen unserer Verarbeitung Daten anderen Personen und Unternehmen wie Webhostern, Cloudanbietern, Auftragsverarbeitern oder Dritten offenbaren, sie an diese übermitteln oder Ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), wenn die Betroffenen eingewilligt haben oder eine rechtliche Verpflichtung dies vorsieht.
    </p>
    <h3>
      3. Dauer der Speicherung personenbezogener Daten
    </h3>
    <p>
      Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten gelöscht, sofern sie nicht mehr zur Zweckerreichung, Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
    </p>
    <h3>
      4. Übermittlungen in Drittländer
    </h3>
    <p>
      Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor-)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten wir oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten, d.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
    </p>

    <h2>
      V. Datenverarbeitung im Rahmen des Besuchs unserer Website
    </h2>
    <h3>
      1. Protokolldateien
    </h3>
    <p>
      Bei jedem Zugriff durch eine betroffene Person auf unsere Website werden allgemeine Daten und Informationen in den Logfiles unseres Systems gespeichert:
      <ul style="padding: 20px 40px">
        <li>
          Datum und Uhrzeit des Abrufs (Zeitstempel);
        </li>
        <li>
          Anfragedetails und Zieladresse (Protokollversion, HTTP-Methode, Referer, UserAgent-String);
        </li>
        <li>
          Name der abgerufenen Datei und übertragene Datenmenge (angefragte URL inkl. Query-String, Größe in Byte);
        </li>
        <li>
          Meldung, ob der Abruf erfolgreich war (HTTP Status Code).
        </li>
      </ul>
      Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf die betroffene Person. Es erfolgt keine personenbezogene Auswertung oder eine Auswertung der Daten zu Marketingzwecken oder eine Profilbildung. Die IP-Adresse wird in diesem Zusammenhang nicht gespeichert.
    </p>
    <p>
      Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den sicheren Betrieb unserer Website zwingend erforderlich. Es besteht folglich seitens der betroffenen Person keine Widerspruchsmöglichkeit.
    </p>
    <h3>
      2. Schadsoftware-Erkennung und Protokolldatenauswertung
    </h3>
    <p>
      Wir erheben Protokolldaten, die beim Betrieb von Kommunikationstechnik unseres Unternehmens anfallen, und werten diese automatisiert aus, soweit dies zum Erkennen, Eingrenzen oder Beseitigen von Störungen oder Fehlern bei der Kommunikationstechnik oder der Abwehr von Angriffen auf unsere Informationstechnik bzw. die Erkennung und die Abwehr von Schadprogrammen erforderlich ist.
    </p>
    <p>
      Rechtsgrundlage für die vorübergehende Speicherung und Auswertung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Die Speicherung und Auswertung der Daten ist zur Bereitstellung der Website und für deren sicheren Betrieb zwingend erforderlich. Es besteht folglich seitens der betroffenen Person keine Widerspruchsmöglichkeit.
    </p>
    <h3>
      3. Cookies
    </h3>
    <p>
      Auf unserer Website werden sogenannte Cookies eingesetzt. Cookies sind kleine Textdateien, die zwischen Webbrowser und dem Hosting-Server ausgetauscht werden. Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unsere Seite übermittelt. In dem jeweils genutzten Webbrowser können Sie die Verwendung von Cookies durch eine entsprechende Einstellung einschränken oder grundsätzlich verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Werden Cookies für unsere Website deaktiviert, kann dies dazu führen, dass die Website nicht im kompletten Umfang angezeigt oder verwendet werden kann.
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
    </p>
    <h3>
      4. Hosting
    </h3>
    <p>
      Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs unserer Website einsetzen.
    </p>
    <p>
      Hierbei verarbeiten wir bzw. unser Auftragsverarbeiter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Nutzern unserer Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO iVm Art. 28 DSGVO (Abschluss eines Vertrages zur Auftragsverarbeitung).
    </p>
    <h3>
      5. Einsatz von Google Analytics und Google Tag Manager
    </h3>
    <p>
      (1) Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
    </p>
    <p>
      (2) Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
    </p>
    <p>
      (3) Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre  Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren:
      <a target="_blank" href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
    </p>
    <p>
      (4) Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
    </p>
    <p>
      (5) Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework. Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
    </p>
    <p>
      (6) Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen:
      <a target="_blank" href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a> , Übersicht zum Datenschutz: <a target="_blank" href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>.
    </p>
    <p>
      (7) Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von Besucherströmen, die über eine User-ID durchgeführt wird. Sie können das Tracking hier jederzeit beenden.
    </p>

    <!-- <unvergessen-cookie-select style="margin-bottom: 20px"></unvergessen-cookie-select> -->

    <p>
      (8) Einsatz der Anwendung Google Tag Manager: Der Google Tag Manager ist eine Anwendung, mit der Website-Tags über eine Oberfläche verwaltet werden können. Die Anwendung Google Tag Manager selbst (das die Tags implementiert) ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Die Anwendung sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Der Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Cookie- oder Domainebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Google Analytics und Google Tag Manager ist Art. 6 Abs. 1 lit. a DSGVO.
    </p>
    <h3>
      6. Einsatz von Google Fonts
    </h3>
    <p>
      Unsere Internetseite nutzt zur einheitlichen Darstellung von Texten und Schriftarten sogenannte Google Fonts, die von der Google Inc. zur Verfügung gestellt werden. Wird eine Seite aufgerufen, lädt Ihr Internet-Browser die benötigten Fronts in Ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss Ihr Browser Verbindung zu den Servern der Google Inc.  herstellen. Dadurch erlangt die Google Inc. die Kenntnis davon, dass über Ihre IP-Adresse unsere Internet-Seite aufgerufen wurde. Die Nutzung von Google Fronts dient unserem Interesse einer einheitlichen und bildlich ansprechenden Darstellung unseres Onlineangebots. Dies stellt ein sogenanntes berechtigtes Interesse im Sinne von Art. 6 Abs. 1 Buchstabe f. DSGVO dar.
    </p>
    <p>
      Sollte Ihr Browser Google Fonts nicht unterstützen, wird eine Standardschrift von Ihrem PC genutzt.
    </p>
    <p>
      Zusätzliche Informationen zu Google Fronts finden Sie unter <a target="_blank" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> sowie in der Datenschutzerklärung der Google Inc. unter <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
    </p>
    <h3>
      7. Facebook-Retargeting und Conversion
    </h3>
    <p>
      Wir nutzen das Pixel der Facebook Ireland Limited (Website-Custom-Audience-Pixel). Durch dieses Pixel werden Informationen über die Nutzung dieser Website (z.B. Informationen über angesehene Seiten) durch eternio und die Facebook Ireland Limited in gemeinschaftlicher Verantwortlichkeit erho-ben und an die Facebook Ireland Limited übermittelt. Diese Informationen können Ihnen unter Zuhil-fenahme weiterer Informationen, die die Facebook Ireland Limited z.B. aufgrund Ihrer Inhaberschaft eines Accounts auf dem sozialen Netzwerk „Facebook“ über Sie gespeichert haben, zugeordnet wer-den. Anhand der über das Pixel erhobenen Informationen können Ihnen in Ihrem Facebook-Account interessenbezogene Werbeeinblendungen angezeigt werden (Retargeting). Die über das Pixel erhobe-nen Informationen können außerdem durch die Facebook Ireland Limited aggregiert werden und die aggregierten Informationen können durch die Facebook Ireland Limited zu eigenen Webezwecken sowie zu Werbezwecken Dritter genutzt werden. So kann die Facebook Ireland Limited z.B. aus Ihrem Surfverhalten auf dieser Website auf bestimmte Interessen schließen und diese Informationen auch dazu nutzen, um Angebote Dritter zu bewerben. Die Facebook Ireland Limited kann die über das Pixel erhobenen Informationen außerdem mit weiteren Informationen, die die Facebook Ireland Limited über Sie über andere Webseiten und / oder im Zusammenhang mit der Nutzung des sozialen Netz-werks „Facebook“ erhoben hat, verbinden, so dass bei der Facebook Ireland Limited ein Profil über Sie gespeichert werden kann. Dieses Profil kann zu Zwecken der Werbung genutzt werden. Für die dauer-hafte Speicherung und die dargestellte Weiterverarbeitung der über das auf dieser Website eingesetz-te Website-Custom-Audience-Pixel erhobenen Trackingdaten ist die Facebook Ireland Limited alleinig verantwortlich.
    </p>
    <p>
      Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen erweiterten Abgleich („Advanced Matching“) aktiviert. Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen, Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder Telefonnummer als zusätzliche Informationen an Facebook zu senden, sofern Sie uns diese Daten zur Verfügung gestellt haben und dafür Ihre Einwilligung erteilt haben. Diese Aktivierung ermöglicht uns Werbekampagnen auf Face-book noch genauer auf Menschen, die sich für unsere Dienstleistungen oder Produkte interessieren, anzupassen.
    </p>
    <p>
      Rechtsgrundlage für diese Datenverarbeitung ist Artikel 6 Absatz 1 Buchstabe a) DSGVO.
    </p>
    <p>
      Nähere Informationen zum Datenschutz bei der Facebook Ireland Limited finden Sie <a href="https://www.facebook.com/policy.php" target="_blank">https://www.facebook.com/policy.php</a>. Hier finden Sie auch die Möglichkeit der Geltendmachung Ihrer Betroffenenrechte (z.B. Recht auf Löschung) gegenüber der Facebook Ireland Limited. Ihre Einwilligung können Sie jederzeit hier widerrufen.
    </p>

    <!-- <unvergessen-cookie-select style="margin-bottom: 20px"></unvergessen-cookie-select> -->

    <h3>
      8. Einsatz von Facebook-Plugins
    </h3>
    <p>
      Unser Internetauftritt verwendet Plugins des sozialen Netzwerks Facebook, des Anbieters Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA.
    </p>
    <p>
      Durch die Nutzung unserer Internetseite, wird über dieses Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt und Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. durch einen Klick auf den Facebook „Like-Button“ während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seite mit Ihrem Facebook-Profil verlinken. Facebook kann dadurch den Besuch unserer Seite mit Ihrem Benutzerkonto in Verbindung bringen.
    </p>
    <p>
      Wir weisen Sie darauf hin, dass wir als Anbieter unserer Internetseite keine Kenntnis der an Facebook übermittelten und von diesen genutzten Daten haben. Weitere Informationen zur Datennutzung finden Sie unter <a target="_blank" href="http://de-de.facebook.com/about/privacy/">http://de-de.facebook.com/about/privacy/</a>.
    </p>
    <p>
      Loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus, bevor Sie unsere Seite besuchen, wenn Sie nicht wünschen, dass Facebook den Besuch unseres Internetauftritts Ihrem Benutzerkonto zuordnet.
    </p>
    <h3>
      9. Einbindung von YouTube-Videos
    </h3>
    <p>
      Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf <a target="_blank" href="http://www.YouTube.com">http://www.YouTube.com</a> gespeichert sind und von unserer Website aus direkt abspielbar sind. Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die in Absatz 2 genannten Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.
    </p>
    <p>
      Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die o.g. Serverdaten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten musst.
    </p>
    <p>
      Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:
      <a target="_blank" href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/privacy</a>. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen,
      <a target="_blank" href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
    </p>
    <h3>
      10. Einsatz von Google-Maps
    </h3>
    <p>
      Auf unserer Internetseite setzen wir die Komponente „Google-Maps“ des Unternehmens Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, (nachfolgend Google) ein. Bei der Nutzung der auf unserer Internetseite eingebauten Komponente „Google Maps“ setzt Google ein Cookie, um bei der Anzeige der Internetseite, auf der die Komponente „Google Maps“ integriert ist, Einstellungen und Daten des Nutzers zu verarbeiten. Dieses Cookie bleibt in der Regel auch nach Schließen des Browsers bestehen, soweit es nicht manuell gelöscht wird oder durch Zeitablauf abläuft.
    </p>
    <p>
      Soweit Sie mit dieser Datenverarbeitung durch die „Google-Maps“- Komponente nicht einverstanden sind, haben Sie die Möglichkeit, „Google Maps“ zu deaktivieren, sodass eine Datenübertragung an „Google“ nicht mehr stattfindet. In diesem Fall kann der Service von „Google Maps“ jedoch nicht bzw. nur noch eingeschränkt nutzbar sein.
    </p>
    <p>
      Die Nutzung des Services „Google Maps“ erfolgt zu den Nutzungsbedingungen von „Google“, zu finden auf:
    </p>
    <p>
      <a target="_blank" href="https://policies.google.com/terms?hl=de&gl=de">https://policies.google.com/terms?hl=de&gl=de</a>
    </p>
    <p>
      und den zusätzlichen Nutzungsbedingungen:
    </p>
    <p>
      <a target="_blank" href="https://www.google.com/intl/de_de/help/terms_maps.html">https://www.google.com/intl/de_de/help/terms_maps.html</a>
    </p>
    <h3>
      10. Einsatz von Google-Maps
    </h3>
    <p>
      Auf unserer Internetseite setzen wir die Komponente „Google-Maps“ des Unternehmens Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, (nachfolgend Google) ein. Bei der Nut-zung der auf unserer Internetseite eingebauten Komponente „Google Maps“ setzt Google ein Cookie, um bei der Anzeige der Internetseite, auf der die Komponente „Google Maps“ integriert ist, Einstel-lungen und Daten des Nutzers zu verarbeiten. Dieses Cookie bleibt in der Regel auch nach Schließen des Browsers bestehen, soweit es nicht manuell gelöscht wird oder durch Zeitablauf abläuft.
    </p>
    <p>
      Soweit Sie mit dieser Datenverarbeitung durch die „Google-Maps“- Komponente nicht einverstanden sind, haben Sie die Möglichkeit, „Google Maps“ zu deaktivieren, sodass eine Datenübertragung an „Google“ nicht mehr stattfindet. In diesem Fall kann der Service von „Google Maps“ jedoch nicht bzw. nur noch eingeschränkt nutzbar sein.
    </p>
    <p>
      Die Nutzung des Services „Google Maps“ erfolgt zu den Nutzungsbedingungen von „Google“, zu finden auf:
    </p>
    <p>
      <a href="https://policies.google.com/terms?hl=de&gl=de" target="_blank">https://policies.google.com/terms?hl=de&gl=de</a>
    </p>
    <p>
      und den zusätzlichen Nutzungsbedingungen:
    </p>
    <p>
      <a href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank">https://www.google.com/intl/de_de/help/terms_maps.html</a>
    </p>
    <h3>
      11. Einsatz der Zahlmethode Stripe
    </h3>
    <p>
      Auf unserer Website bieten wir u.a. die Bezahlung über den Dienstleister Stripe und die hierüber angebotenen Bezahlmethoden an. Anbieter dieser Zahlungsdienste ist Stripe Payments Europe Ltd, Block 4, Harcourt Centre, Harcourt Road, Dublin 2. Beim Bezahlvorgang via Stipe werden die von Ihnen eingegebenen Daten an Stripe Payments Europe Ltd übermittelt.
    </p>
    <p>
      Die Übermittlung Ihrer Daten an Stripe erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO (Verarbeitung zur Erfüllung eines Vertrags). Alle für die Zahlungsabwicklung erforderlichen Daten werden ausschließlich für die durchführung der Zahlungen verwendet und über das Verfahren “SSL” übermittelt. Stripe verarbeitet gegebenenfalls personenbezogene Daten außerhalb der EU/EWR. Dabei unterliegt Stripe dem Safe Harbour Abkommen. Informationen zum Datenschutz der Stripe Payments Europe Ltd finden Sie hier:
      <a target="_blank" href="https://stripe.com/de/privacy">https://stripe.com/de/privacy</a>.
    </p>
    <h3>
      12. Einsatz der Bezahlmöglichkeit Paypal
    </h3>
    <p>
      Soweit Sie sich im Rahmen Ihres Bestellvorgangs für eine Bezahlung mittels „PayPal“ (Kauf auf Rech-nung / Kreditkarte / Lastschrift) entscheiden, werden Ihre personenbezogenen Daten automatisiert an PayPal übermittelt. PayPal ist ein Angebot der PayPal (Europe) S.à.r.l. & Cie. S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg. 
    </p>
    <p>
      Bei den an PayPal zur Verfügung gestellten personenbezogenen Daten handelt es sich üblicherweise um Personenstammdaten wie Vorname, Nachname, Adresse, E-Mail-Adresse, IP-Adresse oder andere Daten, die zur Zahlungsabwicklung erforderlich sind. Zur Abwicklung des Vertrages notwendig sind auch solche personenbezogenen Daten, die im Zusammenhang mit Ihrer Bestellung stehen (wie beispielsweise Artikelanzahl, Artikelnummer, Rechnungsbetrag, Steuern und sonstige Rechnungsinformationen).
    </p>
    <p>
      Die Übermittlung Ihrer Daten ist zur Zahlungsabwicklung über den Dritten PayPal notwendig und bezweckt die Validierung Ihrer Identität und die Ausführung Ihrer Zahlungsanweisung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 b) DSGVO.
    </p>
    <p>
      Auf die Datenschutzbestimmungen von Paypal wird verwiesen: <a target="_blank" href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.
    </p>
    <h3>
      13. Einsatz von Facebook / Google / Apple - Einlogmöglichkeiten
    </h3>
    <p>
      Mit den von uns eingesetzten Einlogbuttons von Facebook, Google und Apple kann über einen bestehenden Account bei diesen Anbietern ein neuer Account bei uns eröffnet bzw. eine Verbindung zu einem Account bei uns hergestellt werden. Wenn Sie einen Account bei uns über o. g. Anbieter erstellen oder sich über diese in einen bestehenden Account bei uns einloggen, erhalten wir Zugriff auf Ihr bei diesen Anbietern hinterlegtes öffentliches Profil und Ihre E-Mail-Adresse. Diese Angaben nutzen wir, um Ihren Account bei uns zu erstellen, vorauszufüllen oder zu aktualisieren und Ihnen dann zur Verfügung zu stellen. Da wir Ihre Daten insoweit zur Erstellung eines Accounts bei uns nutzen, ist die Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
    </p>
    <h3>
      14. Hubspot
    </h3>
    <p>
      Wir verwenden auf unserer Website HubSpot für Marketing-Aktivitäten. HubSpot ist ein Software-Unternehmen aus den USA mit einer Niederlassung HubSpot Ireland Limited in 2nd Floor 30 North Wall Quay, Dublin 1, Ireland.
    </p>
    <p>
      Diese integrierte Software-Lösung verwenden wir für unser eigenes Marketing, die Lead-Generierung und für Kundendienstzwecke. Hierzu zählen unter anderem das E-Mail-Marketing, welches den Ver-sand von Newsletter sowie automatisierte Mailings regelt, Social Media Publishing und Reporting, Kontaktmanagement wie die Nutzersegmentierung und CRM, Landing Pages und Kontaktformulare.
    </p>
    <p>
      HubSpot verwendet Cookies, kleine Textdateien, die lokal im Zwischenspeicher Ihres Webbrowsers auf Ihrem Endgerät gespeichert werden und eine Analyse Ihrer Benutzung der Website durch uns ermögli-chen. Die erfassten Informationen (z.B. IP-Adresse, der geographische Standort, Art des Browsers, Dauer des Besuchs und aufgerufene Seiten) wertet HubSpot im Auftrag von uns aus, damit wir Reports über den Besuch und die besuchten Seiten generieren können.
    </p>
    <p>
      Mittels HubSpot erfasste Informationen sowie die Inhalte unserer Website werden auf Servern der Service-Providern von HubSpot gespeichert. Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, erfolgt die Verarbeitung auf dieser Website zum Zweck der Websiteanalyse.
    </p>
    <p>
      Da eine Übertragung personenbezogener Daten in die USA erfolgt, sind weitere Schutzmechanismen erforderlich, die das Datenschutzniveau der DSGVO sicherstellen. Um dies zu gewährleisten, haben wir mit dem Anbieter Standarddatenschutzklauseln gemäß Art. 46 Abs. 2 lit. c DSGVO vereinbart. Diese verpflichten den Empfänger der Daten in den USA die Daten entsprechend dem Schutzniveau in Euro-pa zu verarbeiten. In Fällen, in denen dies auch durch diese vertragliche Erweiterung nicht sicherge-stellt werden kann, bemühen wir uns um darüberhinausgehende Regelungen und Zusagen des Emp-fängers in den USA.
    </p>
    <p>
      Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr er-forderlich sind.
    </p>
    <p>
      Sie können der Erfassung der Daten durch HubSpot und der Setzung von Cookies dauerhaft widerspre-chen, indem Sie die Speicherung von Cookies durch Ihre Browsereinstellungen entsprechend verhin-dern. Sie können gegen die Verarbeitung Ihrer personenbezogenen Daten jederzeit mit Wirkung für die Zukunft Widerspruch einlegen.
    </p>
    <h3>
      15. Leadinfo
    </h3>
    <p>
      Wir nutzen den Lead-Generation-Service von Leadinfo B.V., Rotterdam, Niederlande. Dieser erkennt Besuche von Unternehmen auf unserer Website anhand von IP-Adressen und zeigt uns hierzu öffent-lich verfügbare Informationen, wie z.B. Firmennamen oder Adressen. Darüber hinaus setzt Leadinfo zwei First-Party-Cookies zur Auswertung des Nutzerverhaltens auf unserer Website und verarbeitet Domains aus Formulareingaben (z.B. „leadinfo.com“), um IP-Adressen mit Unternehmen zu korrelieren und die Services zu verbessern. Weitere Informationen finden Sie unter www.leadinfo.com. Auf dieser Seite: www.leadinfo.com/en/opt-out haben Sie eine Opt-out Möglichkeit. Im Falle eines Opt-outs werden Ihre Daten von Leadinfo nicht mehr erfasst.
    </p>

    <h2>
      VI. Datenverarbeitung im Rahmen der Kontaktaufnahme
    </h2>
    <h3>
      1. Kontaktaufnahme per E-Mail
    </h3>
    <p>
      Die Kontaktaufnahme zu uns per E-Mail ist über die auf unserer Website veröffentlichten E-Mail-Adressen möglich.
    </p>
    <p>
      Soweit Sie diesen Kontaktweg verwenden, werden die von Ihnen übermittelten Daten (z.B. Name, Vorname, Anschrift), zumindest jedoch die E-Mail-Adresse, sowie die in der E-Mail enthaltenen Informationen nebst den von Ihnen übermittelten ggf. personenbezogenen Daten zum Zwecke der Kontaktaufnahme und Bearbeitung Ihres Anliegens gespeichert. Zudem werden folgende Daten durch unser System erhoben:
    </p>
    <p>
      <ul style="padding: 0 40px">
        <li>
          IP-Adresse des aufrufenden Rechners;
        </li>
        <li>
          Datum und Uhrzeit der E-Mail.
        </li>
      </ul>
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten im Rahmen uns übermittelter E-Mails ist Art. 6 Abs. 1 lit. b bzw. lit. f DSGVO.
    </p>
    <h3>
      2. Kontaktaufnahme per Website-Kontaktformular
    </h3>
    <p>
      Soweit Sie das auf unserer Website bereitgestellte Kontaktformular zur Kommunikation verwenden, ist die Angabe Ihres Namens und Vornamens sowie Ihrer E-Mail-Adresse erforderlich. Ohne diese Daten kann Ihr per Kontaktformular übermitteltes Anliegen nicht bearbeitet werden. Die Angabe der Anschrift ist optional und ermöglicht uns, soweit von Ihnengewünscht, die Bearbeitung Ihres Anliegens auf postalischem Weg.
    </p>
    <p>
      Zudem werden folgende Daten durch unser System erhoben:
    </p>
    <p>
      <ul style="padding: 0 40px">
        <li>
          IP-Adresse des aufrufenden Rechners;
        </li>
        <li>
          Datum und Uhrzeit der Registrierung.
        </li>
      </ul>
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten im Rahmen uns übermittelter E-Mails ist Art. 6 Abs. 1 lit. b bzw. lit. f DSGVO.
    </p>
    <h3>
      3. Kontaktaufnahme per Brief
    </h3>
    <p>
      Sofern Sie uns einen Brief übermitteln, werden die von Ihnen übermittelten Daten (z.B. Name, Vorname, Anschrift) und die im Brief bzw. Telefax enthaltenen Informationen nebst von Ihnen übermittelten ggf. personenbezogenen Daten zum Zwecke der Kontaktaufnahme und Bearbeitung Ihres Anliegens gespeichert.
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten im Rahmen uns übermittelter Briefe und Telefaxe ist Art. 6 Abs. 1 lit. b bzw. lit. f DSGVO.
    </p>
    <h3>
      4. Kontaktaufnahme per WhatsApp, Facebook-Messenger
    </h3>
    <p>
      Wenn Sie die von uns angebotenen Kontaktmöglichkeit über WhatsApp bzw. Facebook-Messenger nutzen wollen, ist es erforderlich, dass Sie bereits über ein bestehendes Konto bei WhatsApp bzw. Facebook verfügen. Verantwortlicher Anbieter des Messenger-Dienstes sind WhatsApp bzw. Facebook. WhatsApp bzw. Facebook ist für uns weder als Auftragsverarbeiter, noch in gemeinsamer Verantwortlichkeit tätig. Wir haben keine genaue Kenntnis und keinen Einfluss auf die Datenverarbeitung durch WhatsApp bzw. Facebook. Weitere Informationen zum Datenschutz bei WhatsApp finden Sie in der Datenschutzerklärung des jeweiligen Messenger-Dienstes. Wir verarbeiten die in Ihrem WhatsApp- bzw. Facebook -Konto hinterlegten Vornamen, Namen, Mobilfunknummer, ggf. Foto und das Datum der Anmeldung zu unserem Service. Diese Speicherung dient dem Nachweis Ihrer Anmeldung, der durchführung des Services und dem Ausschluss etwaiger Missbräuche Dritter. Weitere Daten werden nicht erhoben. Die genannten Daten werden nur für die genannten Zwecke verwendet und werden nicht an Dritte weitergegeben.
    </p>
    <p>
      Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten im Rahmen uns übermittelter Nachrichten über WhatsApp bzw. Facebook-Messenger W ist Art. 6 Abs. 1 lit. a DSGVO. Ihre Einwilligung zur Datenverarbeitung ist freiwillig und kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Der Widerruf kann per Nachricht über WhatsApp bzw. Facebook-Messenger oder per E-Mail an die im Impressum genannten Kontaktdaten erfolgen.
    </p>
    <h3>
      5. Kontaktaufnahme per Live-Chat des Unternehmens Userlike UG (haftungsbeschränkt)
    </h3>
    <p>
      Wir nutzen einen Live-Chat des Unternehmens Userlike UG (haftungsbeschränkt), Probsteigasse 44-46, 50670 Köln, Deutschland. Sie können den Live-Chat wie ein Kontaktformular nutzen, um nahezu in Echtzeit mit unseren Mitarbeitern zu chatten. Beim Start des Chats fallen folgende personenbezogene Daten an:
    </p>
    <p>
      <ul style="padding: 0 40px">
        <li>
          Datum und Uhrzeit des Aufrufs
        </li>
        <li>
          Browsertyp/-version,
        </li>
        <li>
          IP-Adresse
        </li>
        <li>
          Verwendetes Betriebssystem
        </li>
        <li>
          URL der zuvor besuchten Webseite
        </li>
        <li>
          Menge der gesendeten Daten
        </li>
        <li>
          Und wenn angegeben: Vorname, Name und E-Mail-Adresse
        </li>
      </ul>
    </p>
    <p>
      Je nach Gesprächsverlauf mit unseren Mitarbeitern, können im Chat weitere personenbezogene Daten anfallen, die durch Sie eingetragen werden. Die Art dieser Daten hängt stark von Ihrer Anfrage ab oder dem Problem, welches Sie uns schildern. Die Verarbeitung all dieser Daten dient dazu, Ihnen eine schnelle und effiziente Kontaktmöglichkeit zur Verfügung zu stellen und somit unseren Kundenservice zu verbessern.
    </p>
    <p>
      Alle unsere Mitarbeiter wurden und werden zum Thema des Datenschutzes geschult und zum sicheren und vertrauensvollen Umgang mit Kundendaten unterrichtet.
    </p>
    <p>
      Durch das Aufrufen der Webseite www.unvergessen-agentur.de, wird das Chat-Widget in Form einer JavaScriptDatei von AWS Cloudfront geladen. Das Chat-Widget stellt technisch den Quellcode dar, der auf Ihrem Computer ausgeführt wird und den Chat ermöglicht.
    </p>
    <p>
      Darüber hinaus speichern wir den Verlauf der Live-Chats für die Dauer von sieben Tagen. Dies dient dem Zweck, Ihnen unter Umständen umfangreiche Ausführungen zur Historie Ihrer Anfragen zu ersparen sowie zur beständigen Qualitätskontrolle unseres Live-Chat-Angebots. Die Verarbeitung ist deshalb gemäß Art. 6 Abs. 1 Buchst. f DSGVO erlaubt. Sofern Sie dies nicht wünschen, können Sie uns dies gerne unter den am Anfang dieser Datenschutzerklärung aufgeführten Kontaktdaten mitteilen. Gespeicherte Live-Chats werden dann unverzüglich von uns gelöscht. Die Speicherung der Chatdaten dient auch dem Zweck, die Sicherheit unserer informationstechnischen Systeme zu gewährleisten. Hierin liegt zugleich unser berechtigtes Interesse, weshalb die Verarbeitung nach Art. 6 Abs. 1 Buchst. f DSGVO zulässig ist. Weitere Informationen finden Sie in den <a href="http://www.userlike.com/terms#privacypolicy" target="_blank">Datenschutzbestimmungen der Userlike UG (haftungsbeschränkt)</a>.
    </p>

    <h3>
      6. Kontaktaufnahme durch Push-Nachrichten
    </h3>
    <p>
      CleverPush ist ein Push-Benachrichtigungs-Service. Dieser wird von uns eingesetzt, um Nutzern unseres Angebotes Push-Nachrichten zu Neuigkeiten zu unserem Unternehmen zu senden.
    </p>
    <p>
      Dieser Service wird von unserem Partner CleverPush bereitgestellt: Just Viral GmbH & Co. KG, Reinholdstraße 5, 21073 Hamburg. Die Datenschutzerklärung von Cleverpush finden Sie hier: <a href="https://cleverpush.com/en/privacy" target="_blank">https://cleverpush.com/en/privacy</a>
    </p>
    <p>
      CleverPush erhebt keine Nutzerdaten wie IP-Adressen o. ä., welche direkte Rückschlüsse auf den jeweiligen Nutzer liefern können. Beim Eintragen in den Benachrichtigungs-Verteiler wird lediglich eine Art Identifikator, welcher von Browser zu Browser variiert, an unsere Server gesendet. Dieser Identifikator wird von den Browserherstellern (Google, Mozilla, Apple etc.) vergeben und ermöglicht es uns, die Benachrichtigungen an die jeweiligen Browser zu versenden.
    </p>
    <p>
      Zur CleverPush-FAQ geht es hier!: <a href="https://cleverpush.com/de/faq" target="_blank">https://cleverpush.com/de/faq</a>
    </p>
    <p>
      Wie kann ich mich von den Push-Notifications abmelden?<br>
      Push-Notifications im Chrome Browser abschalten:<br>
      Chrome → Einstellungen → über den Reiter „Erweitert” dann auf „Sicherheit und Datenschutz” → Inhaltseinstellungen → Benachrichtigungen → auf die drei Punkte neben dem Browser-Push-Symbol wählen und entfernen / blockieren.<br>
      Push-Notifications im Firefox Browser abschalten:<br>
      Firefox → Einstellungen → Datenschutz und Sicherheit → in dem Bereich „Benachrichtigungen” → Einstellungen → Blockieren auswählen im Dropdown<br>
      Push-Notifications im Safari Browser abschalten:<br>
      Safari → Einstellungen → Websites → Mittelungen → im Dropdown auswählen:  nicht erlauben<br>
      Push-Notifications im Android Chrome Browser abschalten:<br>
      Chrome-App → „Mehr“-Symbol (3 Punkte) → Einstellungen → Website-Einstellungen → Benachrichtigungen → Deaktivieren
    </p>

    <h2>
      VII. Datenverarbeitung bei Bezug unseres Newsletters
    </h2>
    <p>
      Wenn Sie sich auf unserem Newsletter-Verteiler eintragen, werden Ihre E-Mail-Adresse sowie der von Ihnen gewählte Newsletter von uns auf einem Server gespeichert.
    </p>
    <p>
      Zudem werden systemseitig folgende Daten bei der Anmeldung erhoben:
    </p>
    <p>
      <ul style="padding: 0 40px">
        <li>
          IP-Adresse des aufrufenden Rechners;
        </li>
        <li>
          Datum und Uhrzeit der Registrierung.
        </li>
      </ul>
    </p>
    <p>
      Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen. Die Verarbeitung der Daten erfolgt auf Grundlage Ihres Einverständnisses nach Art. 6 Abs. 1 lit. a DSGVO und im Rahmen des berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO.
    </p>
    <p>
      Wir verwenden diese Daten ausschließlich für den Versand des Newsletters. Wir geben Ihre Daten zur Erbringung dieser Leistung ggf.  an Dritte weiter; diese Auftragsverarbeiter sind durch Auftragsverarbeitungsvereinbarungen hinreichend auf die Einhaltung des Datenschutzes verpflichtet. Durch das Anmeldesystem mit einer zusätzlichen Bestätigungsnachricht, die einen Link zur endgültigen Registrierung enthält (Double-Opt-In), ist sichergestellt, dass der Newsletter von Ihnen und nicht von einem Dritten angefordert wurde. Bei der Registrierung werden Ihre Daten auf nur für uns zugängliche Servern unseres Auftragsverarbeiters gespeichert und eine Bestätigungsnachricht mit einem Link zur endgültigen Registrierung an die angegebene E-Mail-Adresse generiert. Soweit Sie die Registrierung nicht durch den Link in dieser E-Mail bestätigen, werden die Daten nach 24 Stunden gelöscht. Erst durch Bestätigung des Links in der E-Mail werden Ihre Daten zum Newsletterversand für die Dauer der Nutzung unseres Angebots gespeichert.
    </p>
    <p>
      Soweit Sie mit der Speicherung der Daten zu diesem Zweck nicht mehr einverstanden sind und somit unser Angebot nicht mehr nutzen möchten, können Sie sich jederzeit von unserem Newsletter abmelden. Zu diesem Zweck findet sich in jedem Newsletter ein entsprechender Link. Die von Ihnen zum Newsletterbezug angegebenen personenbezogenen Daten werden dann gelöscht.
    </p>

    <h2>
      VIII. Ihre Rechte
    </h2>
    <p>
      Als betroffene Person steht Ihnen im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten die folgenden Rechte zu:
    </p>
    <h3>
      1. Auskunftsrecht
    </h3>
    <p>
      (1) Die betroffene Person hat das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so hat sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf folgende Informationen:
    </p>
    <p>
      <ol type="a" style="padding: 0 40px">
        <li>
          die Verarbeitungszwecke;
        </li>
        <li>
          die Kategorien personenbezogener Daten, die verarbeitet werden;
        </li>
        <li>
          die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
        </li>
        <li>
          falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
        </li>
        <li>
          das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
        </li>
        <li>
          das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
        </li>
        <li>
          wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
        </li>
        <li>
          das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und Abs. 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
        </li>
      </ol>
    </p>
    <p>
      (2) Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so hat die betroffene Person das Recht, über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
    </p>
    <h3>
      2. Recht auf Berichtigung
    </h3>
    <p>
      Die betroffene Person hat das Recht, von dem Verantwortlichen unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung hat die betroffene Person das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
    </p>
    <h3>
      3. Recht auf Löschung
    </h3>
    <p>
      (1) Die betroffene Person hat das Recht, von dem Verantwortlichen zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
    </p>
    <p>
      <ol type="a" style="padding: 0 40px">
        <li>
          Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
        </li>
        <li>
          Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 lit. a) oder Art. 9 Abs. 2 lit. a) DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
        </li>
        <li>
          Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
        </li>
        <li>
          Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
        </li>
        <li>
          Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
        </li>
        <li>
          Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
        </li>
      </ol>
    </p>
    <p>
      (2) Hat der Verantwortliche die personenbezogenen Daten öffentlich gemacht und ist er gemäß Absatz 1 zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass eine betroffene Person von Ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat.
    </p>
    <p>
      (3) Die Absätze 1 und 2 gelten nicht, soweit die Verarbeitung erforderlich ist
    </p>
    <p>
      <ol type="a" style="padding: 0 40px">
        <li>
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
        </li>
        <li>
          zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
        </li>
        <li>
          aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h) und i) sowie Art. 9  Abs. 3 DSGVO;
        </li>
        <li>
          für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Art. 89 Abs. 1, soweit das in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
        </li>
        <li>
          zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </li>
      </ol>
    </p>
    <h3>
      4. Recht auf Einschränkung der Verarbeitung
    </h3>
    <p>
      (1) Die betroffene Person hat das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
    </p>
    <p>
      <ol type="a" style="padding: 0 40px">
        <li>
          die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
        </li>
        <li>
          die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;
        </li>
        <li>
          der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder
        </li>
        <li>
          die betroffene Person Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
        </li>
      </ol>
    </p>
    <p>
      (2) Wurde die Verarbeitung gemäß Absatz 1 eingeschränkt, so dürfen diese personenbezogenen Daten – von ihrer Speicherung abgesehen – nur mit Einwilligung der betroffenen Person oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
    </p>
    <h3>
      5. Recht auf Datenübertragbarkeit
    </h3>
    <p>
      (1) Die betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten, die sie einem Verantwortlichen bereitgestellt hat, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und sie hat das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
    </p>
    <p>
      <ol type="a" style="padding: 0 40px">
        <li>
          die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 lit. a) oder Art. 9 Abs. 2 lit. a) DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b) DSGVO beruht und
        </li>
        <li>
          die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </li>
      </ol>
    </p>
    <p>
      (2) Bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
    </p>
    <p>
      Das Recht gemäß Absatz 1 darf die Rechte und Freiheiten anderer Personen nicht beeinträchtigen.
    </p>
    <p>
      Dieses Recht gilt nicht für eine Verarbeitung, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
    </p>
    <h3>
      6. Widerspruchsrecht
    </h3>
    <p>
      Die betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>
    <p>
      Im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft kann die betroffene Person ungeachtet der Richtlinie 2002/58/EG ihr Widerspruchsrecht mittels automatisierter Verfahren ausüben, bei denen technische Spezifikationen verwendet werden.
    </p>
    <h3>
      7. Widerrufsrecht
    </h3>
    <p>
      Die betroffene Person hat das Recht, ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
    </p>
    <h3>
      8.Recht auf Beschwerde bei einer Aufsichtsbehörde
    </h3>
    <p>
      Jede betroffene Person hat unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn die betroffene Person der Ansicht ist, dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt.
    </p>

    <h2>
      IX. Änderungen dieser Datenschutzerklärung
    </h2>
    <p>
      Wir behalten uns das Recht vor, diese Datenschutzbestimmungen jederzeit mit Wirkung für die Zukunft zu ändern. Eine jeweils aktuelle Version ist auf der Website verfügbar. Bitte suchen Sie die Website regelmäßig auf und informieren Sie sich über die geltenden Datenschutzbestimmungen.
    </p>
    <p>
      Stand: 03/2022
    </p>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { smAndDown } = useDisplay()

    return { smAndDown }
  }
}
// import CookieSelect from '@/components/privacy/CookieSelect.vue'
// export default {
//   components: {
//     unvergessenCookieSelect: CookieSelect
//   }
// }
</script>

<style scoped>
  h3 {
    margin-bottom: 15px;
    margin-top: 30px
  }
  h2 {
    margin-bottom: 15px;
    margin-top: 30px
  }
</style>
