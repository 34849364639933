<template>
  <div
    :style="{ padding: lgAndUp ? '100px 15%' : md ? '80px 100px' : '25px' }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { md, lgAndUp } = useDisplay()

    return { md, lgAndUp }
  }
}
</script>