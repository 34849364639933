<template>
  <div
    :class="{ services: smAndUp, 'services-m': xs }"
  >
    <home-container>
      <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 10px 0' : '20px 0 10px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
        Das machen wir
      </div>

      <div :style="{ 'margin': mdAndUp ? '0  auto 30px' : '0px 0 30px 0' }" class="text-h6" style="max-width: 1100px;">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              Strategie- & Konzeptentwicklung
            </v-col>
            <v-col cols="12" sm="6" md="3">
              Individuelle Inhalt-Erstellung
            </v-col>
            <v-col cols="12" sm="6" md="3">
              Maßgeschneiderte Kanal-Betreuung
            </v-col>
            <v-col cols="12" sm="6" md="3">
              Erreichung Ihrer <br v-if="!xs">Ziele
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-carousel
        hide-delimiters
        style="max-width: 600px; margin: auto;"
        :style="{ 'margin-top': xs ? '-50px' : '' }"
      >
        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="$logo-candle"
            headline="Verständnis der Zielgruppe"
            special="true"
          >
            Über 25 Jahre Trauer- und Social Media Erfahrung. Reiner Fokus auf Bestattungsinstitute.
          </expertise-card>
        </v-carousel-item>

        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="mdi-share-variant"
            headline="Garantierte Reichweiten"
          >
            Sie erreichen mind. 10.000 Menschen pro Monat in einer für Sie relevanten Zielgruppe
          </expertise-card>
        </v-carousel-item>

        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="$paper-plane"
            headline="Strategie & Konzept"
          >
            Entwicklung eines konkreten und umsetzbaren Fahrplans, inkl. durchgehender Beratung
          </expertise-card>
        </v-carousel-item>

        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="$edit-photo"
            headline="Inhalte finden & produzieren"
          >
            Komplettes Social Media Management inklusive Redaktionsplanung
          </expertise-card>
        </v-carousel-item>

        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="$socials"
            headline="Betreuung der Kanäle"
          >
            Facebook, Instagram, TikTok, LinkedIn, Podcast, YouTube, WhatsApp, Pinterest
          </expertise-card>
        </v-carousel-item>
        
        <v-carousel-item :style="{ padding: xs ? '50px' : '' }">
          <expertise-card
            icon="$chat"
            headline="Moderation & Kommunikation"
          >
            Intensive 24/7 Betreuung und Vermeidung negativer Kommentare
          </expertise-card>
        </v-carousel-item>
      </v-carousel>

      <!-- Download Button -->
      <div style="padding: 5% 40px 0; max-width: 700px; margin: auto">
        <v-btn color="primary" width="100%" min-height="50px" @click="optIn">Herunterladen</v-btn>
      </div>
    </home-container>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import ExpertiseCard from '@/components/home/expertise/ExpertiseCard.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, smAndUp, mdAndUp, smAndDown } = useDisplay()

    return { xs, smAndUp, mdAndUp, smAndDown }
  },
  methods: {
    optIn () {
      this.$router.push({ name: 'DownloadPage' })
    }
  },
  components: {
    HomeContainer,
    ExpertiseCard
  }
}
</script>

<style scoped>
.mobile-item {
  padding: 50px
}
.services {
  background-image: url('@/assets/home/Unvergessen_Agentur_Leistungen_Blumen_Bestattung.jpg');
  background-size: cover;
  background-position: center
}

.services-m {
  background-image: url('@/assets/home/Unvergessen_Agentur_Leistungen_Blumen_Bestattung_m.jpg');
  background-size: cover;
  background-position: center
}
</style>
