import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import JournalLandingView from '../views/landing-pages/JournalLandingView.vue'
import AgenturLandingView from '../views/landing-pages/AgenturLandingView.vue'
import DownloadView from '../views/opt-in/DownloadView.vue'
import JournalDownloadView from '../views/opt-in/JournalDownloadView.vue'
import ContactView from '../views/ContactView.vue'
import ImprintView from '../views/legal/ImprintView.vue'
import PrivacyView from '../views/legal/PrivacyView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Journal',
    name: 'JournalLanding',
    component: JournalLandingView
  },
  {
    path: '/Agentur',
    name: 'AgenturLanding',
    component: AgenturLandingView
  },
  {
    path: '/Download',
    name: 'DownloadPage',
    component: DownloadView
  },
  {
    path: '/Journal-Download',
    name: 'JournalDownloadPage',
    component: JournalDownloadView
  },
  {
    path: '/Kontakt',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/Impressum',
    name: 'Imprint',
    component: ImprintView
  },
  {
    path: '/Datenschutz',
    name: 'Privacy',
    component: PrivacyView
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
