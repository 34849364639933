<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="144.219" height="140.759" viewBox="0 0 144.219 140.759">
    <g id="Gruppe_715" data-name="Gruppe 715" transform="translate(-108 -255)">
      <g id="Gruppe_714" data-name="Gruppe 714">
        <g id="Gruppe_712" data-name="Gruppe 712">
          <path id="Pfad_646" data-name="Pfad 646" d="M92.832,134.117a63.988,63.988,0,1,0-63.98-63.988,63.988,63.988,0,0,0,63.98,63.988Z" transform="translate(87.275 248.859)" fill="#fff3e7" fill-rule="evenodd"/>
          <path id="Pfad_647" data-name="Pfad 647" d="M131.988,173.293A63.982,63.982,0,0,0,173.571,60.679a63.981,63.981,0,0,1-90.209,90.209,63.85,63.85,0,0,0,48.626,22.4Z" transform="translate(48.119 209.684)" fill="#eee3d8" fill-rule="evenodd"/>
          <path id="Pfad_648" data-name="Pfad 648" d="M192.95,214.913v18.332c0,8.36,7.146,7.889,7.146,18.556h21.223c0-15.9,6.651-14.628,6.651-29.99,0-14.676-1.406-30.86-16.864-32.377-4.327-.423-7.9,3.553-7.9,7.9v17.574a5.126,5.126,0,1,0-10.252,0Z" transform="translate(-30.602 117.219)" fill="#f6caae" fill-rule="evenodd"/>
          <path id="Pfad_649" data-name="Pfad 649" d="M194.112,417.747v15.562a4.947,4.947,0,0,0,4.359,4.359h26.142a4.949,4.949,0,0,0,4.368-4.359V417.747a6.843,6.843,0,0,0-6.827-6.819H200.931A6.836,6.836,0,0,0,194.112,417.747Z" transform="translate(-31.436 -41.908)" fill="#024d38" fill-rule="evenodd"/>
          <path id="Pfad_650" data-name="Pfad 650" d="M239.547,160.1l15.874-9.166c7.234-4.184,3.258-10.14,12.5-15.466l-10.611-18.38c-13.765,7.945-15.993,1.549-29.3,9.23-12.7,7.338-26.022,16.656-19.6,30.8,1.8,3.96,7.034,5.07,10.795,2.9l15.227-8.791a5.126,5.126,0,1,0,5.126,8.879Z" transform="translate(-40.502 169.162)" fill="#d3a489" fill-rule="evenodd"/>
          <path id="Pfad_651" data-name="Pfad 651" d="M403.886,93.3l13.478-7.777a4.957,4.957,0,0,0,1.6-5.956L405.889,56.922a4.965,4.965,0,0,0-5.964-1.6l-13.47,7.785a6.842,6.842,0,0,0-2.5,9.318l10.611,18.38A6.839,6.839,0,0,0,403.886,93.3Z" transform="translate(-167.152 213.824)" fill="#00b484" fill-rule="evenodd"/>
          <path id="Pfad_652" data-name="Pfad 652" d="M134.194,129.346l-15.881-9.174c-7.234-4.176-10.4,2.252-19.634-3.082L88.067,135.47c13.765,7.945,9.334,13.071,22.644,20.752,12.7,7.338,27.435,14.213,36.474,1.581,2.531-3.529.87-8.623-2.9-10.795l-15.218-8.791a5.123,5.123,0,1,0,5.126-8.871Z" transform="translate(44.74 169.162)" fill="#eab599" fill-rule="evenodd"/>
          <path id="Pfad_653" data-name="Pfad 653" d="M32.919,63.109,19.441,55.324a4.957,4.957,0,0,0-5.956,1.6L.414,79.565a4.946,4.946,0,0,0,1.6,5.956L15.488,93.3a6.84,6.84,0,0,0,9.318-2.491l10.612-18.38a6.842,6.842,0,0,0-2.5-9.318Z" transform="translate(108 213.824)" fill="#007656" fill-rule="evenodd"/>
          <path id="Pfad_654" data-name="Pfad 654" d="M432.42,93.235a6.834,6.834,0,0,0,6.931.064l13.478-7.777a4.957,4.957,0,0,0,1.6-5.956L441.354,56.922a4.965,4.965,0,0,0-5.964-1.6l-1.645.958a5.509,5.509,0,0,1,.567.639l13.071,22.644a4.957,4.957,0,0,1-1.6,5.956Z" transform="translate(-202.617 213.824)" fill="#32bd98" fill-rule="evenodd"/>
          <path id="Pfad_655" data-name="Pfad 655" d="M64.209,72.422,53.6,90.8a6.78,6.78,0,0,1-2.4,2.427l.119.064A6.84,6.84,0,0,0,60.64,90.8l10.612-18.38a6.842,6.842,0,0,0-2.5-9.318L55.274,55.319a4.944,4.944,0,0,0-5.389.958L61.71,63.1a6.841,6.841,0,0,1,2.5,9.318Z" transform="translate(72.166 213.83)" fill="#3c6d60" fill-rule="evenodd"/>
          <path id="Pfad_656" data-name="Pfad 656" d="M275.49,433.309a4.948,4.948,0,0,1-4.368,4.359h7.042a4.949,4.949,0,0,0,4.368-4.359V417.747a6.752,6.752,0,0,0-1.445-4.176,6.827,6.827,0,0,0-5.381-2.643h-7.042a6.843,6.843,0,0,1,6.827,6.819Z" transform="translate(-84.986 -41.908)" fill="#003527" fill-rule="evenodd"/>
          <path id="Pfad_657" data-name="Pfad 657" d="M320.252,119.788l7.944,13.757a2.844,2.844,0,0,1-.934,3.266c-6.811,4.679-3.816,10.292-10.452,14.125l-11.138,6.428L302,159.487a5.156,5.156,0,0,0,5.98.615l15.874-9.166c7.234-4.184,3.258-10.14,12.5-15.466l-10.611-18.38a41.275,41.275,0,0,1-5.485,2.7Z" transform="translate(-108.934 169.162)" fill="#bf8e75" fill-rule="evenodd"/>
          <path id="Pfad_658" data-name="Pfad 658" d="M190.376,151.028a8.011,8.011,0,0,1,3.825,8.895,6.484,6.484,0,0,1-.934,1.9,15.63,15.63,0,0,1-9.837,6.7c6.38,1.054,12.3-.367,16.815-6.611.024-.032.04-.056.064-.088a6.8,6.8,0,0,0,.95-5.781l-.048-.168a8.32,8.32,0,0,0-3.8-4.847l-13.582-7.841-1.637-.95a4.99,4.99,0,0,0,2,.655,5.161,5.161,0,0,0,4.136-1.413,4.852,4.852,0,0,0,.87-1.118,5.133,5.133,0,0,0-.368-5.669,5.027,5.027,0,0,0-1.509-1.326l-15.881-9.174c-3.761-2.172-6.427-1.477-9.414-1.054a11.789,11.789,0,0,1,2.371,1.054l15.881,9.174a5.138,5.138,0,0,1,2.292,6.076,4.735,4.735,0,0,1-.415.918,5.083,5.083,0,0,1-1.262,1.461,5.194,5.194,0,0,1-1.517.822c-1.03.423-.207,1.9,1.046,2.643Z" transform="translate(-8.385 165.141)" fill="#eeb08f" fill-rule="evenodd"/>
          <path id="Pfad_659" data-name="Pfad 659" d="M275.314,273.916c0,15.362-6.651,14.093-6.651,29.99h7.043c0-15.9,6.651-14.628,6.651-29.99,0-3.026-.056-6.108-.288-9.118a5.178,5.178,0,0,1-4.016-1.261,5.239,5.239,0,0,1-1.022-1.262c-.064-.112-.128-.224-.184-.336a19.57,19.57,0,0,1-1.868,2.236C275.25,267.385,275.314,270.682,275.314,273.916Z" transform="translate(-84.986 65.113)" fill="#eab599" fill-rule="evenodd"/>
        </g>
      </g>
    </g>
  </svg>
</template>