<template>
  <v-btn
    class="font-weight-medium"
    style="text-transform: none"
    :height="mdAndUp ? '70px' : '50px'"
    @click="pushTo({ name: 'Contact' })"
    :style="{ 'font-size': mdAndUp ? '18px' : '' }">
    <slot></slot>
  </v-btn>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  props: ['placement', 'calendly'],
  setup () {
    const { mdAndUp } = useDisplay()

    return { mdAndUp }
  },
  methods: {
    pushTo (target) {
      this.$ga.event('LandingPage', 'Click_on_Contact_Button_' + this.placement, '', 0)
      if (this.calendly) {
        window.open('https://calendly.com/unvergessen/agentur-kennenlernen', '_blank')
      } else {
        this.$router.push(target)
      }
    }
  }
}
</script>
