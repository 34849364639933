<template>
  <home-container>
    <div class="font-weight-medium" :style="{ 'margin': mdAndUp ? '0  0 80px 0' : '20px 0 20px 0' }" :class="{ 'text-h3': mdAndUp, 'text-h4': smAndDown }">
      Unser Erfolg in Zahlen
    </div>

    <v-container v-intersect="playAnimation">
      <v-row :class="{ 'text-h4': mdAndUp, 'text-h6': smAndDown }" :style="{ 'line-height': smAndDown ? '24px' : '' }" class="font-weight-regular">
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Linear.easeOut"
              ref="number0"
              :to="10"
              :duration="2"
            />
          </span><br>
          MitarbeiterInnen
        </v-col>
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Expo.easeOut"
              ref="number1"
              :to="400000"
              :duration="2"
              @click="playAnimation"
            />+
          </span><br>
          Follower
        </v-col>
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Linear.easeOut"
              ref="number2"
              :to="5000000"
              :duration="2"
              @click="playAnimation"
            />+
          </span><br>
          Mtl. Reichweite
        </v-col>
      </v-row>
      <v-row :class="{ 'mt-10': mdAndUp, 'mt-4': smAndDown, 'text-h4': mdAndUp, 'text-h6': smAndDown }" :style="{ 'line-height': smAndDown ? '24px' : '' }" class="font-weight-regular">
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Linear.easeOut"
              ref="number3"
              :to="28"
              :duration="2"
              @click="playAnimation"
            />
          </span><br>
          ⌀ Alter im Team
        </v-col>
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Expo.easeOut"
              ref="number4"
              :to="50000"
              :duration="2"
              @click="playAnimation"
            />+
          </span><br>
          Leads
        </v-col>
        <v-col cols="12" sm="4">
          <span class="text-primary font-weight-bold">
            <number
              tag="span"
              animationPaused
              :format="format"
              easing="Expo.easeOut"
              ref="number5"
              :to="700000"
              :duration="2"
              @click="playAnimation"
            />+
          </span><br>
          Mtl. Interaktionen
        </v-col>
      </v-row>
    </v-container>
    <p :class="{ 'mt-10': mdAndUp, 'mt-5': smAndDown }" :style="{ 'font-size': mdAndUp ? '12px' : '10px' }">
      Die Zahlen beziehen sich auf Kanäle, die wir im Bestattungs- und Trauerumfeld mit sozialen Medien aufgebaut haben.
    </p>
  </home-container>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  data () {
    return {
      intersectCounter: 0
    }
  },
  setup () {
    const { mdAndUp, smAndDown } = useDisplay()

    return { mdAndUp, smAndDown }
  },
  methods: {
    playAnimation () {
      if (this.intersectCounter > 1) return

      this.$refs.number0.restart()
      this.$refs.number1.restart()
      this.$refs.number2.restart()
      this.$refs.number3.restart()
      this.$refs.number4.restart()
      this.$refs.number5.restart()
      this.intersectCounter++
    },
    format (number) {
      return parseInt(number).toLocaleString()
    }
  },
  components: {
    HomeContainer
  }
}
</script>
