import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'
import axios from 'axios'
import ContactButton from '@/components/global/ContactButtonComponent.vue'
import VueAnalytics from 'vue3-analytics'
import VueNumber from 'vue-number-animation'
// import JwtDecode from 'jwt-decode'

loadFonts()

const app = createApp(App)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// const privateKey = Buffer.concat([Buffer.from(process.env.VUE_APP_JWT_KEY)], Buffer.alloc(32).length)

// new jwt.SignJWT({})
//   .setProtectedHeader({ alg: 'ES256' })
//   .setExpirationTime('168h')
//   .sign(privateKey)
//     .then(res => {
//       console.log(res)
//     })

//axios.defaults.headers.common.secret = 'Bearer ' + token

app.component(
  'ContactButton', ContactButton
)

app.use(VueAnalytics, {
  id: 'UA-173171010-4',
  router
})

app.use(VueNumber)

app.use(store).use(router).use(router)
  .use(vuetify, {
    theme: 'light'
  })
  .mount('#app')
