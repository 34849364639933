<template>
  <div>
    <presenter-component></presenter-component>
    <benefits-component></benefits-component>
    <about-component></about-component>
    <services-component></services-component>

    <div>
      <home-container>
        <h1 class="text-h2 font-weight-medium" :style="{ 'margin-bottom': xs ? '20px' : '50px' }" :class="{ 'text-h2': smAndUp, 'text-h4': xs }">
          Journal herunterladen
        </h1>

        <v-card class="unvergessen-shadow" style="max-width: 900px; margin: auto;">
          <v-container>
            <v-row>
              <v-col cols="12" sm="5">
                <div style="margin: 0 auto 10px" :style="{ width: xs ? '100%' : '100%' }">
                  <v-img src="@\assets\journal-page\Social_Media_Journal_Unvergessen_Agentur_Front.jpg" alt="Kunden und Personal finden durch Social Media"></v-img>
                </div>
              </v-col>
              <v-col cols="12" sm="7">
                <div class="d-table" style="height: 100%; width: 100%">
                  <div class="d-table-cell" style="vertical-align: middle">
                    <download-form></download-form>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </home-container>
    </div>
  </div>
</template>

<script>
import HomeContainer from '@/components/home/HomeContainerComponent.vue'
import DownloadForm from '@/components/journal-page/forms/DownloadFormComponent.vue'
import PresenterComponent from '@/components/journal-page/PresenterComponent.vue'
import BenefitsComponent from '@/components/journal-page/BenefitsComponent.vue'
import AboutComponent from '@/components/journal-page/AboutComponent.vue'
import ServicesComponent from '@/components/journal-page/ServicesComponent.vue'
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, smAndUp } = useDisplay()

    return {xs, smAndUp }
  },
  data () {
    return {
    }
  },
  components: {
    HomeContainer,
    PresenterComponent,
    BenefitsComponent,
    AboutComponent,
    ServicesComponent,
    DownloadForm
  }
}
</script>