<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="208" height="139" viewBox="0 0 208 139">
    <g id="Gruppe_695" data-name="Gruppe 695" transform="translate(-76 -256)">
      <path id="XMLID_1189_" d="M5,0H9A0,0,0,0,1,9,0V139a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z" transform="translate(111.002 256)" fill="#e8e8e8"/>
      <path id="XMLID_1191_" d="M0,0H5a5,5,0,0,1,5,5V139a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(120 256)" fill="#d0d0d0"/>
      <path id="XMLID_1193_" d="M5,0H9A0,0,0,0,1,9,0V139a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z" transform="translate(230 256)" fill="#e8e8e8"/>
      <path id="XMLID_1195_" d="M0,0H5a5,5,0,0,1,5,5V139a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(239 256)" fill="#d0d0d0"/>
      <path id="XMLID_1197_" d="M20,0h84a0,0,0,0,1,0,0V79a0,0,0,0,1,0,0H20A20,20,0,0,1,0,59V20A20,20,0,0,1,20,0Z" transform="translate(76 271)" fill="#009970"/>
      <path id="XMLID_1199_" d="M0,0H84a20,20,0,0,1,20,20V59A20,20,0,0,1,84,79H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(180 271)" fill="#007656"/>
      <rect id="XMLID_1201_" width="178" height="49" transform="translate(91 286)" fill="#fafffe"/>
      <text id="WILLKOMMEN" transform="translate(92 294)" fill="#007656" font-size="23" font-family="SegoeUI-Semibold, Segoe UI" font-weight="600"><tspan x="11.476" y="25">WILLKOMMEN</tspan></text>
    </g>
  </svg>
</template>